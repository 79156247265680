<template>
  <div>
    <div v-for="item in localItems" :key="item.id">
      <div class="mt-6 mb-4 d-flex align-items-center justify-content-between">
        <div class="mt-6 mb-4 d-flex align-items-center">
          <h2
            :id="'collapse-' + item.id"
            :aria-controls="'collapse_' + item.id"
            :aria-expanded="!isCollapsed[item.id]"
            :data-bs-target="'#collapse_' + item.id"
            :data-bs-toggle="'collapse'"
            class="me-6 cursor-pointer"
            @click="toggleCollapse(item.id)"
          >
            <span>
              <i v-if="!isCollapsed[item.id]" class="bi bi-caret-down-fill"></i>
              <i v-else class="bi bi-caret-right-fill"></i>
            </span>
            {{ item.name }}
          </h2>
          <button class="btn btn-primary btn-icon btn-sm" @click="showTaskForm(item)">+</button>
        </div>
        <input
          :checked="this.selectedItems[item.id]"
          class="form-check-input me-9"
          type="checkbox"
          @click="selectAll(item.tasks)"
        />
      </div>
      <draggable
        :component-data="{
          tag: 'div',
          type: 'transition-group',
          name: !dragging ? 'flip-list' : null,
        }"
        :disabled="!enabled"
        :list="item.tasks"
        class="list-group mb-8"
        handle=".handle"
        item-key="id"
        v-bind="dragOptions"
        @add="onAdd(item)"
        @end="
          dragging = false;
          onDropped(item);
        "
        @start="dragging = true"
      >
        <template #item="{ element }">
          <div :id="'collapse_' + item.id" :class="{ 'not-draggable': !enabled }" class="collapse show">
            <TaskCard
              :draggable="draggable"
              :selected="checkSelection(element.id)"
              :task="element"
              :viewType="viewType"
              @delete="deleteTask(element)"
              @duplicate="duplicateTask(element)"
              @edit="editTask(element)"
              @select="handleClickSelection"
            ></TaskCard>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { BULK_UPDATE_TASK_ORDER, GET_TASKS } from "../../../data/tasks_graphql";
import TaskCard from "./TaskCard.vue";

export default {
  props: {
    items: Array,
    viewType: String,
    selectedAllLotsIds: {
      type: Array,
      reactive: true,
    },
    projectId: String,
    hideArchived: Boolean,
    taskSortOrder: Array,
    draggable: Boolean,
  },
  components: {
    TaskCard,
    draggable,
  },
  data() {
    return {
      dragging: false,
      enabled: true,
      isCollapsed: {},
      selectAllLot: false,
      selectedItems: {},
      localItems: [],
    };
  },
  watch: {
    selectedAllLotsIds: {
      handler() {
        this.selectedItems = this.localItems.reduce((acc, item) => {
          acc[item.id] = item.tasks.every((task) => this.selectedAllLotsIds.includes(task.id));
          return acc;
        }, {});
      },
      immediate: true,
    },
    items: {
      handler() {
        this.localItems = [...this.items];
        this.localItems.forEach((item) => {
          this.selectedItems[item.id] = false;
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.enabled = this.draggable;
    this.localItems = this.items;
    this.localItems.forEach((item) => {
      this.selectedItems[item.id] = false;
    });
  },
  methods: {
    toggleCollapse(itemId) {
      this.isCollapsed = { ...this.isCollapsed, [itemId]: !this.isCollapsed[itemId] };
    },
    showTaskForm(item) {
      this.$emit("addTask", item);
    },
    deleteTask(task, all = false) {
      this.$emit("delete", task, all);
    },
    duplicateTask(task) {
      this.$emit("duplicate", task);
    },
    editTask(task) {
      this.$emit("edit", task);
    },
    checkSelection(task) {
      if (!this.selectedAllLotsIds) return true;

      return this.selectedAllLotsIds.includes(task);
    },
    handleClickSelection(task) {
      this.$emit("select", task);
    },
    selectAll(tasks) {
      tasks.forEach((task) => {
        this.$emit("select", task);
      });
    },
    onDropped(item) {
      console.log("drop", item);
      this.$apollo.mutate({
        mutation: BULK_UPDATE_TASK_ORDER,
        variables: {
          tasks: [
            ...item.tasks.map((task, index) =>
              JSON.stringify({
                id: task.id,
                order: index,
                lotId: item.id,
              })
            ),
          ],
        },
        refetchQueries: {
          query: GET_TASKS,
          variables() {
            return {
              projectId: this.projectId,
              taskSortOrder: this.taskSortOrder,
              hideArchived: this.hideArchived,
            };
          },
        },
      });
    },
    onAdd(item) {
      console.log("add", item);
      this.localItems = this.localItems.map((i) => {
        if (i.id === item.id) {
          i.tasks = item.tasks;
        }
        return i;
      });
      this.onDropped(item);
    },
  },
  computed: {
    selectedAllLotsIdsInternal: {
      get() {
        return this.selectedAllLotsIds;
      },
      set(value) {
        this.$emit("select", value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "tasks",
        ghostClass: "ghost",
      };
    },
  },
};
</script>
