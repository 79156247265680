<template>
  <div class="">
    <div v-if="this.isDocumentEmpty">
      <div class="text-center mt-12">
        <img alt="No Todo" class="img-fluid w-25" src="/assets/media/svg/empty-states/empty-estimation.svg" />
        <h3 class="card-title my-8">
          <span class="card-label fw-bolder text-dark fs-3">Ce descriptif est vide</span>
        </h3>
        <p class="text-muted">Commencez par ajouter des lots et des emplacements depuis le cockpit du projet</p>
        <div class="row g-2 justify-content-center">
          <a
            class="col-12 col-sm-6 col-xl-4 btn btn-primary fw-bolder"
            @click="this.$router.push({ name: 'project.home' })"
          >
            Aller sur le cockpit du projet
          </a>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div :class="isModalActive ? 'blur-content' : ''" class="d-none d-xxl-block col-12 col-xxl-3 print-hidden">
        <div class="card sticky-top" style="top: 70px">
          <div class="card-header">
            <h3 class="card-title">Table des matières</h3>
          </div>
          <div class="card-body overflow-x-scroll">
            <div
              v-for="lot in this.currentProject?.lots"
              :key="lot.id"
              :class="'ms-' + (lot.number.match(/\./g) || []).length * 4"
              class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
            >
              <a class="link cursor-pointer" @click="this.scrollToLot(`#lot-${lot.id}`)">{{ lot.fullName }}</a>
            </div>
            <button
              class="btn btn-sm btn-primary mt-2"
              @click="
                this.modalLotCancel();
                this.modals.addLot.show = true;
              "
            >
              + Ajouter un lot
            </button>
          </div>
        </div>
      </div>
      <div :class="isModalActive ? 'blur-content' : ''" class="col-12 col-xxl-9 container p-0">
        <div class="mt-5 mb-1 d-flex print-hidden">
          <div class="d-flex w-100 align-items-baseline">
            <div class="w-100 w-sm-50">
              <input
                v-show="this.localDescro.documentName !== null"
                v-model="this.localDescro.documentName"
                class="py-1 form-control form-control-lg text-gray-800"
                placeholder="Document sans titre"
                type="text"
                @input="this.leaveGuard = true"
              />
            </div>
            <div class="w-100 w-sm-50 form-check form-check-reverse pe-0">
              <label class="form-check-label me-2" for="budgetModule">Module budgétaire (CTRL+M)</label>
              <input id="budgetModule" v-model="this.budgetModule" class="form-check-input me-2" type="checkbox" />
            </div>
          </div>
        </div>
        <div class="editor row mt-3 g-0">
          <div
            v-if="editor && isStoreReady"
            v-observe-visibility="this.onElementVisibility"
            class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100"
          >
            <div
              class="print-hidden editor-toolbar toolbar-top px-7 py-1 d-flex justify-content-between align-items-center w-100 mt-0"
            >
              <div class="d-flex">
                <!-- Not shown on >XXL because of permanent table -->
                <button
                  v-tooltip:bottom="'Table des matières'"
                  :disabled="!editor.can().chain().focus().run()"
                  class="btn btn-color-white mx-1 p-2 d-xxl-none"
                  title="Table des matières"
                  @click="this.menuTableOfContentsToggle()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-justify fs-2 p-0"></i>
                  </span>
                </button>
                <div v-tooltip:bottom="'Mode réduit'" class="d-flex" title="Mode réduit">
                  <div class="my-auto pt-1 form-check form-switch form-switch-sm">
                    <input
                      id="descriptionDisplayModeToggle"
                      v-model="isDescriptionDisplayModeCollapsed"
                      class="form-check-input border-white"
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
              <div class="px-5 text-center">
                <button
                  v-tooltip:bottom="'Annuler'"
                  :disabled="!editor.can().chain().focus().undo().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().undo().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-arrow-counterclockwise fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Refaire'"
                  :disabled="!editor.can().chain().focus().redo().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().redo().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-arrow-clockwise fs-2 p-0"></i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <button
                  v-tooltip:bottom="'Imprimer ou télécharger en pdf ou excel'"
                  class="btn btn-color-white mx-1 p-2"
                  @click="modalPrintShow()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-printer fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Importer des ouvrages'"
                  :disabled="!editor.isActive('lotParagraph')"
                  class="btn btn-color-white mx-1 p-2"
                  @click="modalImportShow()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-cloud-download fs-3 p-0"></i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <select
                  v-model="this.menuTypeStyle"
                  class="d-inline form-select align-middle mx-1 px-2 py-1"
                  style="width: 140px"
                  @change="this.setTextHeadingLevel($event)"
                >
                  <option value="paragraph">Texte normal</option>
                  <option value="heading1">Titre</option>
                  <option value="heading2">Titre 1</option>
                  <option value="heading3">Titre 2</option>
                  <option value="heading4">Titre 3</option>
                  <option value="heading5">Titre 4</option>
                  <option value="heading6">Titre 5</option>
                </select>
                <!--            <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 1 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                                        >
                                          <span class="text-center align-middle"> T1 </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 2 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                                        >
                                          <span class="text-center align-middle"> T2 </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 4 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                                        >
                                          <span class="text-center align-middle">
                                            <i class="bi bi-type-h1 fs-2 p-0"></i>
                                          </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Paragraphe'"
                                          :disabled="!editor.can().chain().focus().toggleNode('paragraph', 'text').run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleNode('paragraph', 'text').run()"
                                        >
                                          <span class="text-center align-middle">
                                            <i class="bi bi-justify-left fs-2 p-0"></i>
                                          </span>
                                        </button>-->
                <button
                  v-tooltip:bottom="'Gras'"
                  :class="{ 'is-active': editor.isActive('bold') }"
                  :disabled="!editor.can().chain().focus().toggleBold().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleBold().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-bold fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Italique'"
                  :class="{ 'is-active': editor.isActive('italic') }"
                  :disabled="!editor.can().chain().focus().toggleItalic().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleItalic().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-italic fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Sous-ligner'"
                  :class="{ 'is-active': editor.isActive('underline') }"
                  :disabled="!editor.can().chain().focus().toggleUnderline().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleUnderline().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-underline fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Barrer'"
                  :class="{ 'is-active': editor.isActive('strike') }"
                  :disabled="!editor.can().chain().focus().toggleStrike().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleStrike().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-strikethrough fs-2 p-0"></i>
                  </span>
                </button>
                <button class="btn btn-color-white mx-1 p-2" @click="this.menuColorToggle()">
                  <span
                    :style="'border-bottom: 2px solid' + (editor.getAttributes('textStyle').color || '#000000')"
                    class="text-center"
                  >
                    <i class="bi bi-text-alphabet fs-2 p-0 pb-0 mb-0" style="font-style: normal">A</i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <button
                  v-tooltip:bottom="'Liste à puces'"
                  :class="{ 'is-active': editor.isActive('strike') }"
                  :disabled="!editor.can().chain().focus().toggleStrike().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleBulletList().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-list-ul fs-2 p-0"></i>
                  </span>
                </button>
              </div>
              <div class="">
                <button class="btn btn-color-white mx-1 p-2" title="Versions" @click="this.modalVersionShow()">
                  <span class="bi bi-star-fill fs-2"></span>
                </button>
                <button
                  :disabled="!this.leaveGuard"
                  class="btn btn-color-white mx-1 p-2"
                  title="Enregistrer"
                  @click="this.saveDocument()"
                >
                  <span class="la la-floppy-o fs-1 mt-1 align-middle"></span>
                  <span
                    v-if="this.isSaving"
                    class="mt-1 ms-2 spinner-border spinner-border-sm position-absolute align-middle"
                  ></span>
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="editor && isStoreReady && this.isEditorScrolledDown"
            class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100 px-5 editor-buttons-sticky"
          >
            <div
              class="print-hidden editor-toolbar toolbar-sticky px-7 py-1 d-flex d-flex justify-content-between align-items-center w-100 mt-0"
            >
              <div class="d-flex">
                <!-- Not shown on >XXL because of permanent table -->
                <button
                  v-tooltip:bottom="'Table des matières'"
                  :disabled="!editor.can().chain().focus().undo().run()"
                  class="btn btn-color-white mx-1 p-2 d-xxl-none"
                  title="Table des matières"
                  @click="this.menuTableOfContentsToggle()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-justify fs-2 p-0"></i>
                  </span>
                </button>
                <div v-tooltip:bottom="'Mode réduit'" class="d-flex" title="Mode réduit">
                  <div class="my-auto pt-1 form-check form-switch form-switch-sm">
                    <input
                      id="descriptionDisplayModeToggle"
                      v-model="isDescriptionDisplayModeCollapsed"
                      class="form-check-input border-white"
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
              <div class="px-5 text-center">
                <button
                  v-tooltip:bottom="'Annuler'"
                  :disabled="!editor.can().chain().focus().undo().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().undo().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-arrow-counterclockwise fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Refaire'"
                  :disabled="!editor.can().chain().focus().redo().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().redo().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-arrow-clockwise fs-2 p-0"></i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <button
                  v-tooltip:bottom="'Imprimer ou télécharger .pdf'"
                  class="btn btn-color-white mx-1 p-2"
                  @click="modalPrintShow()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-printer fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Importer des ouvrages'"
                  :disabled="!editor.isActive('lotParagraph')"
                  class="btn btn-color-white mx-1 p-2"
                  @click="modalImportShow()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-cloud-download fs-3 p-0"></i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <select
                  v-model="this.menuTypeStyle"
                  class="d-inline form-select align-middle mx-1 px-2 py-1"
                  style="width: 140px"
                  @change="this.setTextHeadingLevel($event)"
                >
                  <option value="paragraph">Texte normal</option>
                  <option value="heading1">Titre</option>
                  <option value="heading2">Titre 1</option>
                  <option value="heading3">Titre 2</option>
                  <option value="heading4">Titre 3</option>
                  <option value="heading5">Titre 4</option>
                  <option value="heading6">Titre 5</option>
                </select>
                <!--            <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 1 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                                        >
                                          <span class="text-center align-middle"> T1 </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 2 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                                        >
                                          <span class="text-center align-middle"> T2 </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Titre'"
                                          :disabled="!editor.can().chain().focus().toggleHeading({ level: 4 }).run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                                        >
                                          <span class="text-center align-middle">
                                            <i class="bi bi-type-h1 fs-2 p-0"></i>
                                          </span>
                                        </button>
                                        <button
                                          v-tooltip:bottom="'Paragraphe'"
                                          :disabled="!editor.can().chain().focus().toggleNode('paragraph', 'text').run()"
                                          class="btn btn-color-white mx-1 p-2"
                                          @click="editor.chain().focus().toggleNode('paragraph', 'text').run()"
                                        >
                                          <span class="text-center align-middle">
                                            <i class="bi bi-justify-left fs-2 p-0"></i>
                                          </span>
                                        </button>
                                        <span class="text-muted mx-2 my-auto">|</span>-->
                <button
                  v-tooltip:bottom="'Gras'"
                  :class="{ 'is-active': editor.isActive('bold') }"
                  :disabled="!editor.can().chain().focus().toggleBold().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleBold().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-bold fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Italique'"
                  :class="{ 'is-active': editor.isActive('italic') }"
                  :disabled="!editor.can().chain().focus().toggleItalic().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleItalic().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-italic fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Sous-ligner'"
                  :class="{ 'is-active': editor.isActive('underline') }"
                  :disabled="!editor.can().chain().focus().toggleUnderline().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleUnderline().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-underline fs-2 p-0"></i>
                  </span>
                </button>
                <button
                  v-tooltip:bottom="'Barrer'"
                  :class="{ 'is-active': editor.isActive('strike') }"
                  :disabled="!editor.can().chain().focus().toggleStrike().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleStrike().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-type-strikethrough fs-2 p-0"></i>
                  </span>
                </button>
                <button class="btn btn-color-white mx-1 p-2" @click="this.menuColorToggle()">
                  <span
                    :style="'border-bottom: 2px solid' + (editor.getAttributes('textStyle').color || '#000000')"
                    class="text-center"
                  >
                    <i class="bi bi-text-alphabet fs-2 p-0 pb-0 mb-0" style="font-style: normal">A</i>
                  </span>
                </button>
                <span class="text-muted mx-2 my-auto">|</span>
                <button
                  v-tooltip:bottom="'Liste à puces'"
                  :class="{ 'is-active': editor.isActive('strike') }"
                  :disabled="!editor.can().chain().focus().toggleStrike().run()"
                  class="btn btn-color-white mx-1 p-2"
                  @click="editor.chain().focus().toggleBulletList().run()"
                >
                  <span class="text-center align-middle">
                    <i class="bi bi-list-ul fs-2 p-0"></i>
                  </span>
                </button>
              </div>
              <div class="">
                <button class="btn btn-color-white mx-1 p-2" title="Versions" @click="this.modalVersionShow()">
                  <span class="bi bi-star-fill fs-2"></span>
                </button>
                <button
                  :disabled="!this.leaveGuard"
                  class="btn btn-color-white mx-1 p-2"
                  title="Enregistrer"
                  @click="this.saveDocument()"
                >
                  <span class="la la-floppy-o fs-1 mt-1 align-middle"></span>
                  <span
                    v-if="this.isSaving"
                    class="mt-1 ms-2 spinner-border spinner-border-sm position-absolute align-middle"
                  ></span>
                </button>
              </div>
            </div>
            <div
              v-if="this.menuTableOfContents.show"
              class="card shadow w-300px p-3"
              style="position: absolute; top: 60px; left: 10px; height: 500px"
            >
              <i
                class="bi bi-x text-gray-800 text-hover-primary cursor-pointer fs-1"
                style="position: absolute; top: 10px; right: 10px"
                @click="this.menuTableOfContentsToggle()"
              ></i>
              <div class="card-body overflow-x-scroll">
                <div
                  v-for="lot in this.currentProject?.lots"
                  :key="lot.id"
                  :class="'ms-' + (lot.number.match(/\./g) || []).length * 4"
                  class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
                >
                  <a
                    class="link cursor-pointer"
                    @click="
                      this.scrollToLot(`#lot-${lot.id}`);
                      this.menuTableOfContentsToggle();
                    "
                    >{{ lot.fullName }}</a
                  >
                </div>
                <button
                  class="btn btn-sm btn-primary mt-2"
                  @click="
                    this.modalLotCancel();
                    this.modals.addLot.show = true;
                  "
                >
                  + Ajouter un lot
                </button>
              </div>
            </div>
            <div v-if="this.menuColor.show" class="card shadow p-3" style="position: absolute; top: 60px">
              <div class="card-body p-1">
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor1 || '#000000',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor1 || `#000000`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor1 || '#000000')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor2 || '#195D5B',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor2 || `#195D5B`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor2 || '#195D5B')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor3 || '#3C78D8',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor3 || `#3C78D8`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor3 || '#3C78D8')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor4 || '#A64D79',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor4 || `#A64D79`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor4 || '#A64D79')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor5 || '#CC0000',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor5 || `#CC0000`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor5 || '#CC0000')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor6 || '#F1C232',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor6 || `#F1C232`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor6 || '#F1C232')
                      .run()
                  "
                ></button>
                <i
                  class="bi bi-x text-gray-800 text-hover-primary cursor-pointer align-middle fs-1"
                  @click="this.menuColorToggle()"
                ></i>
              </div>
            </div>
            <div
              v-if="this.modalVersion.show"
              class="card shadow w-350px p-3"
              style="position: absolute; top: 60px; right: 10px"
            >
              <i
                class="bi bi-x text-gray-800 text-hover-primary cursor-pointer fs-1"
                style="position: absolute; top: 10px; right: 10px"
                @click="this.modalVersionCancel()"
              ></i>
              <div class="card-body overflow-x-scroll">
                <div v-if="this.versions.length === 0" class="text-center">
                  <p class="text-muted">Aucune version enregistrée sur ce document</p>
                </div>
                <div
                  v-for="version in this.versions"
                  v-else
                  :key="version.id"
                  class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
                >
                  <p class="mb-0 pb-0">
                    <!--                    <button class="btn btn-icon btn-sm btn-outline border-0" disabled>
                      <span class="bi bi-arrow-counterclockwise"></span>
                    </button>-->
                    Version {{ version.number }}
                    <span v-if="version.createdAt"> ({{ new Date(version.createdAt).toLocaleString("fr-FR") }})</span>
                  </p>
                </div>
                <div class="text-center">
                  <button
                    :disabled="this.$apollo.queries.versions.loading"
                    class="btn btn-primary text-center mt-3"
                    @click="this.modalVersionCreate()"
                  >
                    Figer une version<span v-if="this.$apollo.queries.versions.loading" class="spinner-border"></span>
                  </button>
                  <p class="text-muted text-center mt-2">
                    Les versions figées vous seront utiles pour le dossier de consultation des entreprises
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="editor && isStoreReady && !this.isEditorScrolledDown"
            class="justify-content-center d-flex flex-lg-nowrap flex-wrap mx-auto print-hidden w-100 px-5 editor-buttons-sticky"
          >
            <div
              v-if="this.menuTableOfContents.show"
              class="card shadow w-300px p-3"
              style="position: absolute; top: 10px; left: 10px; height: 500px"
            >
              <i
                class="bi bi-x text-gray-800 text-hover-primary cursor-pointer fs-1"
                style="position: absolute; top: 10px; right: 10px"
                @click="this.menuTableOfContentsToggle()"
              ></i>
              <div class="card-body overflow-x-scroll">
                <div
                  v-for="lot in this.currentProject?.lots"
                  :key="lot.id"
                  :class="'ms-' + (lot.number.match(/\./g) || []).length * 4"
                  class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
                >
                  <a
                    class="link cursor-pointer"
                    @click="
                      this.scrollToLot(`#lot-${lot.id}`);
                      this.menuTableOfContentsToggle();
                    "
                    >{{ lot.fullName }}</a
                  >
                </div>
                <button
                  class="btn btn-sm btn-primary mt-2"
                  @click="
                    this.modalLotCancel();
                    this.modals.addLot.show = true;
                  "
                >
                  + Ajouter un lot
                </button>
              </div>
            </div>
            <div v-if="this.menuColor.show" class="card shadow p-3" style="position: absolute; top: 10px">
              <div class="card-body p-1">
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor1 || '#000000',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor1 || `#000000`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor1 || '#000000')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor2 || '#195D5B',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor2 || `#195D5B`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor2 || '#195D5B')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor3 || '#3C78D8',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor3 || `#3C78D8`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor3 || '#3C78D8')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor4 || '#A64D79',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor4 || `#A64D79`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor4 || '#A64D79')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor5 || '#CC0000',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor5 || `#CC0000`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor5 || '#CC0000')
                      .run()
                  "
                ></button>
                <button
                  :class="{
                    'is-active': editor.isActive('textStyle', {
                      color: this.currentUser.org.persoColor6 || '#F1C232',
                    }),
                  }"
                  :style="`background-color: ${this.currentUser.org.persoColor6 || `#F1C232`}; height: 20px; width: 20px;`"
                  class="btn btn-icon rounded-circle m-1"
                  @click="
                    this.editor
                      .chain()
                      .focus()
                      .setColor(this.currentUser.org.persoColor6 || '#F1C232')
                      .run()
                  "
                ></button>
                <i
                  class="bi bi-x text-gray-800 text-hover-primary cursor-pointer align-middle fs-1"
                  @click="this.menuColorToggle()"
                ></i>
              </div>
            </div>
            <div
              v-if="this.modalVersion.show"
              class="card shadow w-350px p-3"
              style="position: absolute; top: 10px; right: 10px"
            >
              <i
                class="bi bi-x text-gray-800 text-hover-primary cursor-pointer fs-1"
                style="position: absolute; top: 10px; right: 10px"
                @click="this.modalVersionCancel()"
              ></i>
              <div class="card-body overflow-x-scroll">
                <div v-if="this.versions.length === 0" class="text-center">
                  <p class="text-muted">Aucune version enregistrée sur ce document</p>
                </div>
                <div
                  v-for="version in this.versions"
                  v-else
                  :key="version.id"
                  class="text-nowrap text-truncate overflow-y-hidden overflow-x-scroll"
                >
                  <p class="mb-0 pb-0">
                    <!--                    <button class="btn btn-icon btn-sm btn-outline border-0" disabled>
                      <span class="bi bi-arrow-counterclockwise"></span>
                    </button>-->
                    Version {{ version.number }}
                    <span v-if="version.createdAt"> ({{ new Date(version.createdAt).toLocaleString("fr-FR") }})</span>
                  </p>
                </div>
                <div class="text-center">
                  <button
                    :disabled="this.$apollo.queries.versions.loading"
                    class="btn btn-primary text-center mt-3"
                    @click="this.modalVersionCreate()"
                  >
                    Figer une version<span v-if="this.$apollo.queries.versions.loading" class="spinner-border"></span>
                  </button>
                  <p class="text-muted text-center mt-2">
                    Les versions figées vous seront utiles pour le dossier de consultation des entreprises
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="editor && isStoreReady" class="form__item form__item--title">
            <PrintHeader
              v-if="this.editor && this.currentFocus"
              :disabled="!this.localDescro.documentName"
              :pageTitle="this.localDescro.documentName"
              sectionTitle=""
            ></PrintHeader>
            <div v-if="this.editor && this.currentFocus">
              <FloatingMenu
                :key="this.floatingMenuKey"
                :editor="this.editor"
                :should-show="this.shouldShowFloatingMenu"
                :tippy-options="{ maxWidth: '100%' }"
                :v-if="this.editor"
                class="floating-menu print-hidden w-100 user-select-none"
              >
                <p class="text-muted h-100 pt-5 align-baseline user-select-none">
                  Tapez « / » pour afficher les commandes…
                </p>
                <!--                <button @click="this.modalImportShow()"><span class="me-2 bi bi-recycle"></span>Importer</button>-->
              </FloatingMenu>
            </div>
            <div class="p-10 print-no-padding overflow-visible">
              <editor-content :key="this.floatingMenuKey" :editor="this.editor" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODALS -->
  <div>
    <!-- MODAL - Import from project -->
    <div
      :style="this.modals.import.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalImportClose()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <div
                    v-if="this.modals.import.page === 0"
                    ref="contactEditModalForm"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                          <p class="text-muted">
                            Choisissez d'où vous voulez importer les ouvrages : un projet, une bibliothèque ou un Excel
                            :
                          </p>
                        </div>
                        <!--end::Heading-->

                        <div class="text-center">
                          <button
                            class="m-1 btn btn-lg btn-active-light btn-color-muted text-center"
                            @click="this.modals.import.page = 10"
                          >
                            <i class="bi bi-file-text"></i>
                            <span class="text-center">Projet</span>
                          </button>

                          <button
                            class="m-1 btn btn-lg btn-active-light btn-color-muted text-center"
                            @click="this.modals.import.page = 11"
                          >
                            <i class="bi bi-bookmark-check"></i>
                            <span class="text-center">Bibliothèque</span>
                          </button>

                          <button
                            class="m-1 btn btn-lg btn-active-light btn-color-muted text-center"
                            @click="this.modals.import.page = 12"
                          >
                            <i class="bi bi-file-spreadsheet"></i>
                            <span class="text-center">Excel</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalImportClose()">Annuler</button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <div
                    v-if="this.modals.import.page === 10"
                    ref="contactEditModalForm"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                          <p class="text-muted">
                            Choisissez d'où vous voulez importer les ouvrages : un projet, une bibliothèque ou un Excel
                            :
                          </p>
                        </div>
                        <!--end::Heading-->

                        <!--begin::Project Selection-->
                        <div class="mb-3 w-100">
                          <label class="fs-6 me-1 fw-bolder text-muted form-label">Projet</label>
                          <select v-model="this.modals.import.projectId" class="form-select form-select-solid">
                            <option
                              v-for="project in [
                                {
                                  id: '',
                                  name: 'Sélectionnez un projet...',
                                  disabled: true,
                                },
                                ...this.getProjects,
                              ]"
                              :key="project.id"
                              :disabled="project.disabled"
                              :value="project.id"
                            >
                              {{ project.name }}
                            </option>
                          </select>
                        </div>
                        <!--end::Project Selection-->

                        <!--begin::Lot Selection-->
                        <div v-if="this.modals.import.projectId" class="w-100 fade show">
                          <label class="fs-6 me-1 fw-bolder text-muted form-label">Lot</label>
                          <select v-model="this.modals.import.lotId" class="form-select form-select-solid">
                            <option
                              v-for="lot in [
                                {
                                  id: '',
                                  fullName: 'Sélectionnez un lot...',
                                  disabled: true,
                                },
                                ...this.modalImportGetLotsForProject(),
                              ]"
                              :key="lot.id"
                              :disabled="lot.disabled"
                              :value="lot.id"
                            >
                              {{ lot.fullName }}
                            </option>
                          </select>
                        </div>
                        <!--end::Lot Selection-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalImportClose()">Annuler</button>
                        <button
                          :disabled="!this.modals.import.projectId || !this.modals.import.lotId"
                          class="btn btn-lg btn-primary ms-2"
                          @click="this.modalImportFetchOuvrages('project')"
                        >
                          Suivant
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <div
                    v-if="this.modals.import.page === 11"
                    ref="contactEditModalForm"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                          <p class="text-muted">
                            Choisissez d'où vous voulez importer les ouvrages : un projet, une bibliothèque ou un Excel
                            :
                          </p>
                        </div>
                        <!--end::Heading-->

                        <!--begin::Biblio Selection-->
                        <div class="w-100">
                          <label class="fs-6 me-1 fw-bolder text-muted form-label">Bibliothèque</label>
                          <select v-model="this.modals.import.biblioId" class="form-select form-select-solid">
                            <option :value="null" disabled>Choisissez une bibliothèque d'ouvrages...</option>
                            <option
                              v-for="biblio in this.biblios.filter((b) =>
                                this.modals.import.includeDefault ? b : b.type !== 'DEFAULT'
                              )"
                              :key="biblio.id"
                              :value="biblio.id"
                            >
                              {{ biblio.name }}
                            </option>
                          </select>
                          <div class="form-check mt-2">
                            <label class="form-check-label text-muted" for="includeDefault"
                              >Inclure les bibliothèques Kalm</label
                            >
                            <input
                              id="includeDefault"
                              v-model="this.modals.import.includeDefault"
                              class="form-check-input form-check-sm"
                              type="checkbox"
                            />
                          </div>
                        </div>
                        <!--end::Biblio Selection-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalImportClose()">Annuler</button>
                        <button
                          :disabled="!this.modals.import.biblioId"
                          class="btn btn-lg btn-primary ms-2"
                          @click="this.modalImportFetchOuvrages('biblio')"
                        >
                          Suivant
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <div
                    v-if="this.modals.import.page === 12"
                    ref="contactEditModalForm"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                          <p class="text-muted">
                            Choisissez d'où vous voulez importer les ouvrages : un projet, une bibliothèque ou un Excel
                            :
                          </p>
                        </div>
                        <!--end::Heading-->

                        <!--begin::Excel Selection-->
                        <div class="w-100">
                          <label class="fs-6 me-1 fw-bolder text-muted form-label">Fichier Excel</label>
                          <input
                            ref="importFileUpload1"
                            accept=".xls,.xlsx"
                            class="form-control"
                            type="file"
                            @change="this.modalImportFromExcelFileChange($event)"
                          />
                        </div>
                        <!--end::Excel Selection-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalImportClose()">Annuler</button>
                        <button
                          :disabled="this.modals.importFromExcel.mode !== 'file'"
                          class="btn btn-lg btn-primary ms-2"
                          @click="this.modalImportFetchOuvrages('excel')"
                        >
                          Suivant
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <div
                    v-if="this.modals.import.page === 1"
                    ref="contactEditModalForm2"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                          <p v-if="this.modals.import.ouvrages.length > 0" class="text-muted">
                            Sélectionnez les ouvrages à importer :
                          </p>
                          <p v-else class="text-muted">Aucun ouvrage à importer</p>
                        </div>
                        <!--end::Heading-->

                        <div class="text-end text-muted">
                          <a
                            class="cursor-pointer"
                            @click="modalImportQuickSelect(this.modals.import.selectedOuvrages, 'all')"
                            >Tout sélectionner</a
                          >
                          /
                          <a
                            class="cursor-pointer"
                            @click="modalImportQuickSelect(this.modals.import.selectedOuvrages, 'none')"
                            >Aucun</a
                          >
                        </div>

                        <div>
                          <div class="mt-2 mb-4">
                            <input
                              v-model="this.modals.import.searchString"
                              class="form-control"
                              placeholder="Rechercher..."
                              type="text"
                            />
                          </div>
                          <div
                            v-if="
                              this.modals.import.ouvrages.length > 0 &&
                              this.modals.import.ouvrages.filter((o) =>
                                JSON.stringify(o).toLowerCase().includes(this.modals.import.searchString.toLowerCase())
                              ).length === 0
                            "
                            class=""
                          >
                            <p class="text-muted text-center">
                              Aucun ouvrage ne correspond à la recherche "{{ this.modals.import.searchString }}"
                            </p>
                          </div>
                          <div
                            v-for="(item, index) in this.modals.import.ouvrages.filter((o) =>
                              JSON.stringify(o).toLowerCase().includes(this.modals.import.searchString.toLowerCase())
                            )"
                            :key="item"
                          >
                            <div class="row">
                              <div class="d-flex">
                                <div class="d-flex flex-column justify-content-center">
                                  <div class="checkbox">
                                    <input
                                      :id="`checkbox-${index}`"
                                      v-model="this.modals.import.selectedOuvrages"
                                      :value="item"
                                      class="form-check-input align-middle"
                                      type="checkbox"
                                    />
                                  </div>
                                </div>
                                <div
                                  v-if="item.type === 'lotOuvrage' && item?.content && item.content.length > 0"
                                  class="ps-5 pt-3 pb-1 w-100"
                                >
                                  <div class="card import-ouvrage-shadow py-3 ps-5">
                                    <label :for="`checkbox-${index}`" class="col-10 cursor-pointer">
                                      <span
                                        v-if="
                                          item.content[0]?.content &&
                                          item.content[0].content.length > 0 &&
                                          item.content[0]?.content[0]?.text
                                        "
                                        class="text-black fw-bold mb-0"
                                        >Ouvrage : {{ item.content[0]?.content[0]?.text }}</span
                                      >
                                    </label>
                                    <label :for="`checkbox-${index}`" class="cursor-pointer">
                                      <span
                                        v-if="
                                          item.content[1]?.content &&
                                          item.content[1].content.length > 0 &&
                                          item.content[1]?.content[0]?.text
                                        "
                                        class="text-muted"
                                      >
                                        {{ item.content[1]?.content[0]?.text
                                        }}<span v-if="item.content.length > 1 || item.content[1].content.length > 0"
                                          >...</span
                                        >
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  v-else-if="item.type === 'lotText' && item?.content[0]?.content"
                                  class="ps-5 pt-3 pb-1 w-100"
                                >
                                  <div class="card import-ouvrage-shadow py-3 ps-5">
                                    <label :for="`checkbox-${index}`" class="col-10">
                                      <span class="text-black fw-bold mb-0"
                                        >Bloc texte : {{ item?.content[0]?.content[0]?.text }}</span
                                      >
                                    </label>
                                    <label
                                      v-if="item?.content[0]?.content[1]"
                                      :for="`checkbox-${index}`"
                                      class="text-muted"
                                    >
                                      <span>{{ item?.content[0]?.content[1] }}</span>
                                      <span v-if="item?.content[0]?.content[2]">...</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end::Step-->

                    <div class="d-flex mb-2 justify-content-end text-muted">
                      <p v-if="this.modals.import.selectedOuvrages.length === 0" class="mb-0">
                        Aucun ouvrage sélectionné
                      </p>
                      <p v-else-if="this.modals.import.selectedOuvrages.length === 1" class="mb-0">
                        1 ouvrage sera importé
                      </p>
                      <p v-else class="mb-0">
                        {{ this.modals.import.selectedOuvrages.length }}
                        ouvrages seront importés
                      </p>
                    </div>

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalImportShow()">Précédent</button>
                        <button
                          :disabled="this.modals.import.selectedOuvrages.length === 0"
                          class="btn btn-lg btn-primary ms-2"
                          @click="modalImportValidate()"
                        >
                          Importer
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL - Import from Excel -->
    <div
      :style="this.modals.importFromExcel.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'"
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalImportFromExcelClose()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <div
                    v-if="this.modals.importFromExcel.page === 0"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                          <p class="text-muted">
                            Importez un fichier Excel contenant les ouvrages à ajouter. La première ligne doit contenir
                            les intitulés de colonnes.
                          </p>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <input
                              ref="importFileUpload2"
                              accept=".xls,.xlsx"
                              class="form-control"
                              type="file"
                              @change="this.modalImportFromExcelFileChange($event)"
                            />
                          </div>
                          <!--                        <p class="col-12 text-muted my-2 text-center">— Ou —</p>
                                                                            <div class="col-12 d-flex">
                                                                              <textarea type="text" placeholder="Collez le tableau ici" class="form-control"
                                                                                     v-model="this.modals.importFromExcel.clipboardData"
                                                                                        @change="this.modalImportFromExcelClipboardChange()"></textarea>
                                                                              <span class="ms-3 align-self-center"><i v-tooltip:bottom="'Pour des ouvrages simples, sans description multi-lignes'" class="bi fs-3 bi-info-circle"></i></span>
                                                                            </div>-->
                        </div>
                        <!--end::Heading-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="this.modalImportFromExcelClose()">Annuler</button>
                        <button
                          :disabled="this.modals.importFromExcel.mode === 'start'"
                          class="btn btn-lg btn-primary ms-2"
                          @click="
                            this.modals.importFromExcel.page = 1;
                            this.modalImportFromExcelFetchColumns();
                          "
                        >
                          Suivant
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <div
                    v-if="this.modals.importFromExcel.page === 1"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Importer des ouvrages</h3>
                          <p class="text-muted">Attribuez chaque colonne du tableur au contenu des blocs ouvrages.</p>
                          <p class="text-muted">
                            Astuce : nommez les colonnes de votre tableur Excel comme ci-dessous (titre, description...)
                            pour que les colonnes soient reconnues automatiquement !
                          </p>
                        </div>
                        <div class="row">
                          <div
                            v-for="attribute in this.modals.importFromExcel.possibleColumns"
                            :key="attribute.id"
                            class="form-group mb-3"
                          >
                            <label class="form-label text-muted"
                              >{{ attribute.label
                              }}<span v-if="attribute.required" class="ms-1 fs-6">(Obligatoire)</span></label
                            >
                            <select v-model="this.modals.importFromExcel.columnMap[attribute.id]" class="form-select">
                              <option :value="null">Aucun</option>
                              <option
                                v-for="(colName, index) in this.modals.importFromExcel.importColumns"
                                :key="colName"
                                :value="index"
                              >
                                {{ colName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="this.modalImportFromExcelClose()">Annuler</button>
                        <button
                          :disabled="!this.modalImportFromExcelCanValidate()"
                          class="btn btn-lg btn-primary ms-2"
                          @click="this.modalImportFromExcelValidate()"
                        >
                          Lancer l'import
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL - Print -->
    <div
      :style="this.modals.print.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'"
      class="modal print-hidden"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalPrintClose()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div
                    ref="contactEditModalForm"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Exporter le descriptif</h3>
                          <p class="text-muted">Choisissez les lots à inclure</p>
                        </div>
                        <!--end::Heading-->

                        <div class="d-flex justify-content-center mb-5">
                          <label
                            class="btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex align-items-center text-start py-3 px-6 me-2"
                            disabled
                          >
                            <span
                              class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1"
                              disabled
                            >
                              <input
                                v-model="this.modals.print.exportFormat"
                                class="form-check-input"
                                type="radio"
                                value="PDF_FULL"
                              />
                            </span>
                            <span class="ms-5">
                              <span class="fs-4 fw-bold text-gray-800 d-block">PDF+</span>
                            </span>
                          </label>
                          <label
                            class="btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex align-items-center text-start py-3 px-6 me-2"
                          >
                            <span
                              class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1"
                            >
                              <input
                                v-model="this.modals.print.exportFormat"
                                class="form-check-input"
                                type="radio"
                                value="PDF"
                              />
                            </span>
                            <span class="ms-5">
                              <span class="fs-4 fw-bold text-gray-800 d-block">Brouillon</span>
                              <span class="font-monospace">PDF&nbsp;rapide</span>
                            </span>
                          </label>
                          <label
                            class="btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex align-items-center text-start py-3 px-6 me-2"
                          >
                            <span
                              class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1"
                            >
                              <input
                                v-model="this.modals.print.exportFormat"
                                class="form-check-input"
                                type="radio"
                                value="EXCEL"
                              />
                            </span>
                            <span class="ms-5">
                              <span class="fs-4 fw-bold text-gray-800 d-block">Excel</span>
                            </span>
                          </label>
                          <!--                          <label
                            class="btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex align-items-center text-start py-3 px-6 me-0"
                          >
                            <span
                              class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1"
                            >
                              <input
                                v-model="this.modals.print.exportFormat"
                                class="form-check-input"
                                type="radio"
                                value="WORD"
                              />
                            </span>
                            <span class="ms-5">
                              <span class="fs-4 fw-bold text-gray-800 d-block">Word</span>
                              <span class="font-monospace">beta</span>
                            </span>
                          </label>-->
                        </div>

                        <!--begin::Form Group Options-->
                        <div class="text-end text-muted">
                          <a class="cursor-pointer" @click="modalPrintLotsQuickSelect(this.modals.print.lots, 'all')"
                            >Tout sélectionner</a
                          >
                          /
                          <a class="cursor-pointer" @click="modalPrintLotsQuickSelect(this.modals.print.lots, 'none')"
                            >Aucun</a
                          >
                        </div>
                        <div v-for="lot in this.currentProject?.lots" :key="lot.id">
                          <label class="d-flex flex-stack mb-6 cursor-pointer">
                            <span class="d-flex align-items-center me-2">
                              <span class="d-flex flex-column">
                                <span :for="lot.id" class="fs-6">{{ lot.fullName }}</span>
                              </span>
                            </span>
                            <span class="form-check form-check-custom form-check-solid">
                              <input
                                :id="lot.id"
                                v-model="this.modals.print.lots"
                                :value="lot.id"
                                class="form-check-input"
                                type="checkbox"
                              />
                            </span>
                          </label>
                        </div>
                        <!--end::Form Group Options-->

                        <div v-if="this.modals.print.exportFormat === 'PDF'">
                          <h4 class="mb-2">Options avancées</h4>
                          <div class="form-check form-switch mb-3">
                            <label class="form-check-label" for="printValueLot">Exporter la somme par lot</label>
                            <input
                              id="printValueLot"
                              v-model="this.printValueLot"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                          <div class="form-check form-switch mb-3">
                            <label class="form-check-label" for="printValueUnitPrice"
                              >Exporter les prix unitaires</label
                            >
                            <input
                              id="printValueUnitPrice"
                              v-model="this.printValueUnitPrice"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                        </div>

                        <div v-if="this.modals.print.exportFormat === 'EXCEL'">
                          <p>Nouvelles options :</p>
                          <div class="mb-3 mt-6 form-check">
                            <label class="form-check-label" for="exportQt">Inclure les quantités</label>
                            <input
                              id="exportQt"
                              v-model="this.modals.print.exportQt"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                          <div class="mb-3 mt-6 form-check">
                            <label class="form-check-label" for="exportUnit">Inclure les unités</label>
                            <input
                              id="exportUnit"
                              v-model="this.modals.print.exportUnit"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                          <div class="mb-3 mt-6 form-check">
                            <label class="form-check-label" for="exportUnitPrice">Inclure les prix unitaires</label>
                            <input
                              id="exportUnitPrice"
                              v-model="this.modals.print.exportUnitPrice"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                        </div>

                        <div v-if="this.modals.print.exportFormat === 'PDF_FULL'">
                          <h3>Options de mise en page :</h3>
                          <div class="input-group input-group-solid mb-3 mt-6">
                            <label class="input-group-text">En-tête du fichier</label>
                            <input v-model="fileHeader" class="form-control form-control-solid" type="text" />
                          </div>
                          <div class="input-group input-group-solid mb-3 mt-6">
                            <label class="input-group-text">Pied de page du fichier</label>
                            <input v-model="fileFooter" class="form-control form-control-solid" type="text" />
                          </div>
                          <div class="mb-3 mt-6 form-check">
                            <label class="form-check-label" for="forceBreakPageLot">
                              Saut de page forcé avant chaque lot
                            </label>
                            <input
                              id="forceBreakPageLot"
                              v-model="this.modals.print.forceBreakPageLot"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                          <div class="mb-3 mt-6 form-check">
                            <label class="form-check-label" for="exportQt">Inclure les quantités</label>
                            <input
                              id="exportQt"
                              v-model="this.modals.print.exportQt"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                          <div class="mb-3 mt-6 form-check">
                            <label class="form-check-label" for="exportUnit">Inclure les unités</label>
                            <input
                              id="exportUnit"
                              v-model="this.modals.print.exportUnit"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                          <div class="mb-3 mt-6 form-check">
                            <label class="form-check-label" for="exportUnitPrice">Inclure les prix unitaires</label>
                            <input
                              id="exportUnitPrice"
                              v-model="this.modals.print.exportUnitPrice"
                              class="form-check-input"
                              type="checkbox"
                            />
                          </div>
                          <div class="input-group input-group-solid mb-3 mt-6">
                            <label class="input-group-text">Marge (haut)</label>
                            <input
                              v-model="this.modals.print.marginTop"
                              class="form-control form-control-solid"
                              type="text"
                            />
                          </div>
                          <div class="input-group input-group-solid mb-3 mt-6">
                            <label class="input-group-text">Marge (bas)</label>
                            <input
                              v-model="this.modals.print.marginBottom"
                              class="form-control form-control-solid"
                              type="text"
                            />
                          </div>
                          <div class="input-group input-group-solid mb-3 mt-6">
                            <label class="input-group-text">Marge (gauche)</label>
                            <input
                              v-model="this.modals.print.marginLeft"
                              class="form-control form-control-solid"
                              type="text"
                            />
                          </div>
                          <div class="input-group input-group-solid mb-3 mt-6">
                            <label class="input-group-text">Marge (droite)</label>
                            <input
                              v-model="this.modals.print.marginRight"
                              class="form-control form-control-solid"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalPrintClose()">Annuler</button>
                        <button class="btn btn-lg btn-primary ms-2" @click="modalPrintValidate()">
                          <span
                            v-if="this.modals.print.loading"
                            class="spinner-border spinner-border-sm align-baseline me-2"
                          ></span
                          >Exporter
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL - Export -->
    <div
      :style="this.modals.export.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'"
      class="modal print-hidden"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="this.modalExportCancel()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div
                    ref="contactEditModalForm"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Exporter le descriptif</h3>
                          <p class="text-muted">Choisissez le type d'export</p>
                        </div>
                        <!--end::Heading-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="this.modalExportCancel()">Annuler</button>
                        <button class="btn btn-lg btn-primary ms-2" @click="this.modalExportCancel()">Suivant</button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL - Force Save -->
    <div
      :style="this.modals.forceSave.show ? 'display: block; z-index: 9999 !important;' : 'display: none;'"
      class="modal print-hidden"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalForceSaveCancel()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div
                    ref="contactEditModalForm"
                    class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                  >
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <!--begin::Heading-->
                        <div class="pb-5">
                          <h3 class="fw-bolder text-dark display-6">Conflit lors de l'enregistrement</h3>
                          <p class="mt-3 text-danger fw-bold">Vérifiez si vous avez d'autres onglets Kalm ouverts</p>
                          <p class="">
                            Un descriptif plus récent a été enregistré sur un autre appareil ou un autre onglet.
                          </p>
                          <p>
                            Attention : en forçant l'enregistrement, cette version sera enregistrée en écrasant les
                            changements réalisés sur les autres onglet ou appareil.
                          </p>
                        </div>
                        <!--end::Heading-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="this.$router.go()">Actualiser</button>
                        <button class="btn btn-lg btn-danger ms-2" @click="modalForceSaveValidate()">
                          Forcer l'enregistrement
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL ADD LOT -->
    <AddLotModal
      v-if="this.modals.addLot.show"
      v-model="this.modals.addLot.default"
      :contacts="this.project?.contacts"
      :lots="this.project?.lots"
      :projectId="this.currentProject?.id"
      @close="modalLotCancel"
      @validate="modalLotValidate"
    ></AddLotModal>
  </div>
</template>

<script>
import kalmStore from "@/store";

import PrintHeader from "@/views/project/tender/PrintHeader";
//import writeXlsxFile from 'write-excel-file'

import { debounce } from "@/utils/debounce";

import { Color } from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import { Underline } from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent, FloatingMenu } from "@tiptap/vue-3";

import Commands from "@/views/project/description/CustomNodes/CommandsList/commands";
import TableOfContentsNode from "@/views/project/description/CustomNodes/TableOfContents/tableOfContentsNode";
import PageBreakNode from "@/views/project/description/CustomNodes/PageBreak/pageBreakNode";
import CustomImage from "@/views/project/description/CustomNodes/CustomImage/customImage";
import suggestion from "@/views/project/description/CustomNodes/CommandsList/suggestion";
import { customDocument, customDropCursor, docLot } from "./DocContentNodes";
import { lotContent, lotGroup, lotHeading, lotOuvrage, lotParagraph, lotText } from "./LotContentNodes";

import {
  ALL_BIBLIO_QUERY,
  ALL_PROJECTS_SIMPLE_QUERY,
  CREATE_VERSION_FOR_PROJECT,
  CURRENT_USER_QUERY,
  GET_PROJECT_BY_ID,
  GET_VERSIONS_FOR_PROJECT,
  UPLOAD_DOCUMENT_MUTATION,
} from "@/graphql/graphql";
import { Paragraph } from "@tiptap/extension-paragraph";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { FILE_UPLOAD } from "@/views/documents/data/files_graphql";
import { FileHandler } from "@tiptap-pro/extension-file-handler";

import { EXPORT_DOCUMENT_EXCEL_MUTATION } from "@/views/project/description/data/documents_graphql";
import readXlsxFile from "read-excel-file";
import AddLotModal from "@/views/project/components/AddLotModal.vue";

export default {
  name: "ProjectDescriptionPage",
  components: {
    AddLotModal,
    FloatingMenu,
    EditorContent,
    PrintHeader,
  },
  apollo: {
    versions: {
      query: GET_VERSIONS_FOR_PROJECT,
      variables() {
        return {
          projectId: this.currentFocus,
        };
      },
      skip() {
        return !this.currentFocus;
      },
    },
    project: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: this.currentFocus,
        };
      },
      result({ data }) {
        console.log("data is equal to", data);
        let document = JSON.parse(data.project.document)?.doc;

        this.localDescro.document = document;
        console.log("oldTime/newTime", this.localDescro.lastDocUpdate, data.project.lastDocUpdate);
        this.localDescro.lastDocUpdate = data.project.lastDocUpdate;
        this.localDescro.documentName = data.project.documentName;
        console.log("local descro updated", this.localDescro);

        return data;
      },
    },
    currentUser: {
      query: CURRENT_USER_QUERY,
    },
    biblios: {
      query: ALL_BIBLIO_QUERY,
    },
    projectsSimple: {
      query: ALL_PROJECTS_SIMPLE_QUERY,
      update: (data) => data.projects,
    },
    projectToImport: {
      query: GET_PROJECT_BY_ID,
      variables() {
        return {
          id: this.modals.import.projectId,
        };
      },
      skip() {
        return !this.modals?.import?.projectId;
      },
      update: (data) => data.project,
    },
  },
  data() {
    return {
      project: null,
      fileHeader: "",
      fileFooter: "",
      biblios: [],
      projectToImport: null,
      projectsSimple: [],
      currentUser: {},
      menuTableOfContents: {
        show: false,
      },
      menuColor: {
        show: false,
      },
      menuTypeStyle: "paragraph",
      editor: null,
      isEditorScrolledDown: false,
      localDescro: {
        id: "",
        document: {},
        documentName: null,
        lastDocUpdate: null,
      },
      leaveGuard: false,
      isSaving: false,
      versions: [],
      modalVersion: {
        show: false,
      },
      modals: {
        addLot: {
          show: false,
          default: {
            number: 0,
            name: "Nouveau Lot",
            assignedCompanyId: null,
          },
        },
        print: {
          show: false,
          lots: [],
          exportFormat: "PDF_FULL",
          exportUnit: false,
          exportUnitPrice: false,
          exportQt: false,
          forceBreakPageLot: true,
          marginTop: "18mm",
          marginBottom: "15mm",
          marginLeft: "18mm",
          marginRight: "18mm",
          loading: false,
        },
        forceSave: {
          show: false,
        },
        import: {
          page: 0,
          show: false,
          searchString: "",
          biblioId: null,
          projectId: "",
          lotId: "",
          ouvrages: [],
          selectedOuvrages: [],
          includeDefault: false,
        },
        export: {
          page: 0,
          show: false,
        },
        importFromExcel: {
          show: false,
          page: 0,
          clipboardData: "",
          fileData: null,
          mode: "clipboard",
          columnMap: {},
          possibleColumns: [
            { label: "Titre", id: "title", required: true },
            { label: "Description", id: "description" },
            /*{ label: 'Lot', id: 'lot'},*/
            /*{ label: 'Emplacement(s)', id: 'locations'},*/
            { label: "Prix unitaire", id: "unitPrice" },
            { label: "Quantité", id: "qt" },
            { label: "Unité", id: "unit" },
          ],
          importColumns: [],
        },
      },
      floatingMenuKey: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.leaveGuard) {
      if (window.confirm("Vous avez des changements non sauvegardés. Êtes-vous sûr de vouloir quitter ?")) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    modalLotCancel() {
      let newNumber = 1;
      if (this.lots.length > 0) {
        const lastLot = this.lots[this.lots.length - 1];
        const lastNumber = parseInt(lastLot.number, 10);
        newNumber = isNaN(lastNumber) ? 1 : lastNumber + 1;
      }
      this.modals.addLot.default = {
        number: newNumber,
        name: "Nouveau lot",
        assignedCompanyId: null,
      };
      this.modals.addLot.show = false;
    },
    modalLotValidate() {
      console.log("modal lot validate callback");
      this.$apollo.query({
        query: GET_PROJECT_BY_ID,
        variables: {
          id: this.project?.id,
        },
      });
      this.modalLotCancel();
    },
    modalVersionShow() {
      this.modalVersion.show = true;
    },
    modalVersionCancel() {
      this.modalVersion.show = false;
    },
    modalVersionCreate() {
      this.$apollo
        .mutate({
          mutation: CREATE_VERSION_FOR_PROJECT,
          variables: {
            projectId: this.currentFocus,
            name: null,
          },
        })
        .then(() => {
          this.$apollo.queries.versions.refetch();
          this.modalVersionCancel();
          toast.success("Version du document enregistrée");
        })
        .catch((error) => {
          toast.error("Erreur lors de la création de la version : " + error.message);
        });
    },
    setTextHeadingLevel($event) {
      const value = $event.target.value;

      console.log("event", value);

      switch (value) {
        case "paragraph":
          this.editor.chain().focus().setParagraph().run();
          break;
        case "heading1":
          this.editor.chain().focus().setHeading({ level: 1 }).run();
          break;
        case "heading2":
          this.editor.chain().focus().setHeading({ level: 2 }).run();
          break;
        case "heading3":
          this.editor.chain().focus().setHeading({ level: 3 }).run();
          break;
        case "heading4":
          this.editor.chain().focus().setHeading({ level: 4 }).run();
          break;
        case "heading5":
          this.editor.chain().focus().setHeading({ level: 5 }).run();
          break;
        case "heading6":
          this.editor.chain().focus().setHeading({ level: 6 }).run();
          break;
      }
    },
    handleKeydown() {
      if ((event.ctrlKey || event.metaKey) && event.key === "m") {
        // toggle budget module
        this.$store.commit("toggleBudgetModule");
      }
    },
    scrollToLot(hash = null) {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          window.scrollTo({
            top: element.offsetTop + 150,
            behavior: "smooth",
          });
        } else {
          console.log("scroll to lot - element DOES NOT EXIST");
        }
      }
    },
    getCommonVariables() {
      return {
        projectId: kalmStore.getters.currentProjectFocus,
        type: "DESCRIPTION",
      };
    },
    onElementVisibility(state) {
      this.isEditorScrolledDown = !state;
    },
    fetchFocusDoc() {
      const doc = this.localDescro.document;
      return doc?.type === "docType" ? doc : null;
    },
    shouldShowFloatingMenu({ state }) {
      return (
        state.selection.$anchor.parent.type.name === "lotParagraph" && state.selection.$anchor.parent.childCount === 0
      );
    },
    syncDocument(newJSONDoc) {
      console.log("sync document");
      this.localDescro.document = newJSONDoc;
      this.saveDocument();
    },
    saveDocument(manualForceSave = false) {
      console.log(
        "[SAVE] start, manualForce=",
        manualForceSave,
        "persoAlwaysForceSave=",
        this.currentUser.org?.persoAlwaysForceSave,
        this.localDescro.lastDocUpdate
      );

      const forceSave = manualForceSave || this.currentUser.org?.persoAlwaysForceSave;

      this.isSaving = true;
      const newDoc = this?.editor.getJSON();

      if (this.$apollo && newDoc !== null) {
        this.$apollo
          .mutate({
            mutation: UPLOAD_DOCUMENT_MUTATION,
            variables: {
              projectId: this.currentFocus,
              newDoc: { doc: newDoc },
              documentName: this.localDescro?.documentName,
              forceSave,
              lastDocUpdate: this.localDescro.lastDocUpdate || "",
            },
            update: (cache, result) => {
              if (result?.data?.updateProjectDocument?.project?.document) {
                this.localDescro.lastDocUpdate = result.data.updateProjectDocument.project.lastDocUpdate;
                this.localDescro.documentName = result.data.updateProjectDocument.project.documentName;
              }
              // TODO get rid of this vuex sync
              kalmStore.dispatch("syncFocusDoc", {
                apolloClient: this.$apollo,
                newJSONDoc: newDoc,
              });
            },
          })
          .then(() => {
            console.log("[SAVE] success");
            this.leaveGuard = false;
            // wait 200ms to avoid flickering
            setTimeout(() => {
              this.isSaving = false;
            }, 200);
            if (manualForceSave) {
              toast.success("Descriptif enregistré avec succès");
            }
          })
          .catch((reason) => {
            this.modalForceSaveShow();
            this.isSaving = false;
            console.log("[SAVE] error", reason);
            console.log("[error] ", reason.message);
          });
      }
    },
    printDocument() {
      this.$nextTick(function () {
        window.print();
      });
    },
    modalPrintShow() {
      this.modals.print.show = true;
      if (this.modals.print.lots?.length === 0) {
        this.modals.print.lots = this.currentProject.lots.map((l) => l.id);
      }
    },
    modalPrintClose() {
      this.modals.print.show = false;
    },
    modalPrintValidate() {
      switch (this.modals.print.exportFormat) {
        case "PDF":
          this.modals.print.show = false;
          this.editor.commands.showLotsInPrint(
            this.lots.map((l) => l.id),
            this.modals.print.lots
          );
          this.printDocument();
          break;
        case "EXCEL":
        case "WORD":
          this.modals.print.loading = true;
          this.$apollo
            .mutate({
              mutation: EXPORT_DOCUMENT_EXCEL_MUTATION,
              variables: {
                projectId: this.currentFocus,
                exportFormat: this.modals.print.exportFormat,
                lots: this.modals.print.lots,
                includeUnit: this.modals.print.exportUnit,
                includeUnitPrice: this.modals.print.exportUnitPrice,
                includeQt: this.modals.print.exportQt,
              },
            })
            .then((result) => {
              if (result?.data?.projectExportDocument?.url) {
                const url =
                  (result?.data?.projectExportDocument.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) +
                  result.data.projectExportDocument.url;
                const filename =
                  (this.project?.name || "") +
                  " - Descriptif." +
                  (this.modals.print.exportFormat === "EXCEL" ? "xlsx" : "docx");

                fetch(url)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                  })
                  .catch(console.error);
                this.modals.print.loading = false;
                this.modals.print.show = false;
              }
            })
            .catch((reason) => {
              console.log("[error] ", reason.message);
              toast.error("Erreur lors de l'export");
              this.modals.print.loading = false;
            });
          break;
        case "PDF_FULL":
          this.modals.print.loading = true;
          this.$apollo
            .mutate({
              mutation: EXPORT_DOCUMENT_EXCEL_MUTATION,
              variables: {
                projectId: this.currentFocus,
                exportFormat: "PDF_FULL",
                lots: this.modals.print.lots,
                fileHeader: this.fileHeader,
                fileFooter: this.fileFooter,
                includeUnit: this.modals.print.exportUnit,
                includeUnitPrice: this.modals.print.exportUnitPrice,
                includeQt: this.modals.print.exportQt,
                forceBreakPageLot: this.modals.print.forceBreakPageLot,
                marginTop: this.modals.print.marginTop,
                marginBottom: this.modals.print.marginBottom,
                marginLeft: this.modals.print.marginLeft,
                marginRight: this.modals.print.marginRight,
              },
            })
            .then((result) => {
              if (result?.data?.projectExportDocument?.url) {
                const url =
                  (result?.data?.projectExportDocument.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) +
                  result.data.projectExportDocument.url;
                const filename = (this.project?.name || "") + " - Descriptif.pdf";

                fetch(url)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                  })
                  .catch(console.error);
                this.modals.print.loading = false;
                this.modals.print.show = false;
              }
            })
            .catch((reason) => {
              console.log("[error] ", reason.message);
              toast.error("Erreur lors de l'export");
              this.modals.print.loading = false;
            });
          break;
        default:
          console.log("aucune action prévue lorsque exportFormat =", this.modals.print.exportFormat);
          return;
      }
    },
    modalExportShow() {},
    modalExportCancel() {},
    modalExportValidate() {},
    modalForceSaveShow() {
      this.modals.forceSave.show = true;
    },
    modalForceSaveCancel() {
      this.modals.forceSave.show = false;
    },
    modalForceSaveValidate() {
      this.saveDocument(true);
      this.modalForceSaveCancel();
    },
    modalImportQuickSelect(selectedOuvrages, mode) {
      if (mode === "all") {
        selectedOuvrages.length = 0;
        this.modals.import.ouvrages.forEach((l) => {
          selectedOuvrages.push(l);
        });
      } else {
        selectedOuvrages.length = 0;
      }
    },
    modalImportFromExcelShow() {
      this.modals.importFromExcel.show = true;
      this.modals.importFromExcel.mode = "start";
      this.modals.importFromExcel.columnMap = {};
      this.modals.importFromExcel.clipboardData = "";
      this.modals.importFromExcel.importColumns = null;
      this.modals.importFromExcel.page = 0;
    },
    modalImportFromExcelClose() {
      this.modals.importFromExcel.show = false;
      this.modals.importFromExcel.mode = "start";
      this.modals.importFromExcel.page = 0;
    },
    modalImportFromExcelValidate() {
      const m = this.modals.importFromExcel.columnMap;

      if (this.modals.importFromExcel.mode === "file") {
        this.modals.importFromExcel.fileData.forEach((l) => {
          // TODO Add try/catch and toast error

          this.editor.commands.insertContent({
            type: "lotOuvrage",
            attrs: {
              locations: l[m["locations"]] || [],
              qt: l[m["qt"]],
              unit: (l[m["unit"]] || "").toLowerCase(),
              unitPrice: l[m["unitPrice"]],
            },
            content: [
              {
                type: "heading",
                attrs: {
                  level: 4,
                },
                content: [
                  {
                    type: "text",
                    text: l[m["title"]],
                  },
                ],
              },
              ...(l[m["description"]] || "").split("\n").map((p) => {
                if (p !== "") {
                  return {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: p,
                      },
                    ],
                  };
                } else {
                  return {
                    type: "paragraph",
                  };
                }
              }),
            ],
          });
        });

        toast.success(
          "" +
            this.modals.importFromExcel.fileData.length +
            (this.modals.importFromExcel.fileData.length > 1 ? " ouvrages importés" : " ouvrage importé")
        );
      } else if (this.modals.importFromExcel.mode === "clipboard") {
        // do stuff
      } else {
        // error
      }

      this.modals.importFromExcel.show = false;
    },
    modalImportFromExcelCanValidate() {
      let ret = true;
      this.modals.importFromExcel.possibleColumns
        .filter((c) => c.required)
        .forEach((c) => {
          if (this.modals.importFromExcel.columnMap[c.id] === null) {
            ret = false;
          }
        });
      return ret;
    },
    modalImportFromExcelClipboardChange() {
      if (this.modals.importFromExcel.clipboardData) {
        this.modals.importFromExcel.mode = "clipboard";
      } else {
        if (!this.modals.importFromExcel.fileData) {
          this.modals.importFromExcel.mode = "start";
        }
      }
    },
    modalImportFromExcelFileChange($event) {
      if ($event.target.files[0]) {
        readXlsxFile($event.target.files[0]).then((rows) => {
          this.modals.importFromExcel.fileData = rows;
          this.modals.importFromExcel.mode = "file";
        });
      } else {
        if (!this.modals.importFromExcel.clipboardData) {
          this.modals.importFromExcel.mode = "start";
        }
      }
    },
    modalImportFromExcelFetchColumns() {
      if (this.modals.importFromExcel.mode === "file") {
        this.modals.importFromExcel.importColumns = this.modals.importFromExcel.fileData[0];
        this.modals.importFromExcel.fileData = this.modals.importFromExcel.fileData.splice(1);
      } else if (this.modals.importFromExcel.mode === "clipboard") {
        this.modals.importFromExcel.importColumns = this.modals.importFromExcel.clipboardData
          .split("\n")[0]
          .split("\t");
      }
      this.modals.importFromExcel.possibleColumns.forEach((c) => {
        this.modals.importFromExcel.columnMap[c.id] = null;
        this.modals.importFromExcel.importColumns.forEach((col, index) => {
          if (col.toLowerCase().includes(c.label.toLowerCase())) {
            this.modals.importFromExcel.columnMap[c.id] = index;
          }
        });
      });
    },
    modalPrintLotsQuickSelect(lots, mode) {
      if (mode === "all") {
        lots.length = 0;
        this.lots.forEach((l) => {
          lots.push(l.id);
        });
      } else {
        lots.length = 0;
      }
    },
    loadEditor() {
      this.floatingMenuKey++;
      this.editor = new Editor({
        extensions: [
          customDocument,
          TableOfContentsNode,
          PageBreakNode,
          docLot,
          lotHeading,
          lotContent,
          lotParagraph,
          lotOuvrage,
          lotText,
          lotGroup,
          Underline,
          TextStyle,
          Color,
          TaskItem,
          TaskList,
          Link,
          Commands.configure({
            suggestion,
          }),
          StarterKit.configure({
            document: false,
            dropcursor: false,
            paragraph: false,
          }),
          Placeholder.configure({
            includeChildren: true,
            showOnlyCurrent: false,
            placeholder: ({ node }) => {
              //console.log(pos, hasParentNodeOfType(editor.state.doc.type.schema.nodes.lotText)(editor.state.selection))
              //'Bloc texte — ajoutez des généralités, un titre...'
              if (node && node?.nodeSize) {
                switch (node.type.name) {
                  case "heading":
                    return "Titre";
                  case "paragraph":
                    if (node) {
                      return "Description — référence, dimensions...";
                    } else {
                      return "paragraphe au sein de bloc texte";
                    }
                  case "lotText":
                    return "bloc texte vide";
                  default:
                    return "Description...";
                }
              } else {
                return "Titre";
              }
            },
          }),
          Paragraph.extend({
            priority: 1001,
          }),
          CustomImage.configure({
            inline: false,
          }),
          FileHandler.configure({
            priority: 10000,
            allowedMimeTypes: ["image/png", "image/jpeg", "image/gif", "image/webp"],

            onDrop: (currentEditor, files, pos) => {
              files.forEach((file) => {
                this.$apollo.mutate({
                  mutation: FILE_UPLOAD,
                  variables: {
                    file,
                    ...this.getCommonVariables(),
                  },
                  context: {
                    hasUpload: true,
                  },
                  update: (cache, result) => {
                    const src =
                      (result.data.fileUpload.fileInfo.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) +
                      result.data.fileUpload.fileInfo.url;
                    currentEditor
                      .chain()
                      .insertContentAt(pos, {
                        type: "image",
                        attrs: {
                          src,
                          width: "50%",
                        },
                      })
                      .focus()
                      .run();
                  },
                });
              });
            },
            onPaste: (currentEditor, files) => {
              files.forEach((file) => {
                this.$apollo.mutate({
                  mutation: FILE_UPLOAD,
                  variables: {
                    file,
                    ...this.getCommonVariables(),
                  },
                  context: {
                    hasUpload: true,
                  },
                  update: (cache, result) => {
                    const src =
                      (result.data.fileUpload.fileInfo.url.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) +
                      result.data.fileUpload.fileInfo.url;
                    currentEditor
                      .chain()
                      .insertContentAt(currentEditor.state.selection.anchor, {
                        type: "image",
                        attrs: {
                          src,
                          width: "50%",
                        },
                      })
                      .focus()
                      .run();
                  },
                });
              });
            },
          }),
          customDropCursor.configure({
            width: 4,
            color: "rgba(1,148,154,0.32)",
          }),
        ],

        editorProps: {
          handleDrop: (view, event, slice, moved) => {
            let ret = true;

            let pos = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            });

            let node = pos && pos.inside >= 0 && view.state.doc.nodeAt(pos.inside);

            let disabled = node && node.type && (node.type.name === "lotHeading" || node.type.name === "docLot");

            if (!disabled && ((moved && pos) || event.dataTransfer)) {
              ret = false;
            }

            return ret;
          },
          transformPastedHTML(html) {
            return html.replace(/<img.*?src="(?<imgSrc>.*?)".*?>/gm, function (match, imgSrc) {
              if (
                imgSrc.startsWith("https://storage.googleapis.com/kalmai-dev/") ||
                imgSrc.startsWith("https://storage.googleapis.com/kalmai-prod/")
              ) {
                // your saved images
                return match; // keep the img
              }
              return ""; // replace it
            });
          },
        },
      });

      this.editor.on("update", () => {
        this.leaveGuard = true;
      });
      this.editor.on(
        "update",
        debounce(({ editor }) => this.syncDocument(editor.getJSON()), 5000)
      );
    },
    modalImportShow() {
      if (this.$refs.importFileUpload1?.value) {
        this.$refs.importFileUpload1.value = null;
      }
      if (this.$refs.importFileUpload2?.value) {
        this.$refs.importFileUpload2.value = null;
      }
      this.modals.import.projectId = "";
      this.modals.import.lotId = "";
      this.modals.import.biblioId = null;
      this.modals.import.page = 0;
      this.modals.import.show = true;
      this.modals.import.selectedOuvrages = [];
    },
    modalImportClose() {
      this.modals.import.show = false;
      this.modals.import.projectId = "";
      this.modals.import.lotId = "";
      this.modals.import.page = 0;
      this.modals.import.selectedOuvrages = [];
    },
    modalImportFetchOuvrages(mode) {
      // 3 possibilities - import from project, import from biblio, import from Excel
      if (mode === "project") {
        try {
          const project = this.projectToImport;
          console.log("hehehe avant", this.modals.import.ouvrages);
          this.modals.import.ouvrages = JSON.parse(project.document)
            .doc.content.find((n) => n.type === "docLot" && n.attrs.id === this.modals.import.lotId)
            .content.find((n) => n.type === "lotContent")
            .content.filter((n) => n.type === "lotOuvrage" || n.type === "lotText")
            .map((n) => {
              // cleanup location & qt fields
              if (n.type === "lotOuvrage") {
                n.attrs.location = "";
                n.attrs.locations = [];
                n.attrs.qt = "";
              }
              return n;
            });
          console.log("hehehe après", this.modals.import.ouvrages);
        } catch (e) {
          this.modals.import.ouvrages = [];
        }

        this.modals.import.page = 1;
      } else if (mode === "biblio") {
        try {
          const biblio = this.biblios.find((b) => b.id === this.modals.import.biblioId);
          this.modals.import.ouvrages = JSON.parse(biblio.document)
            .content.filter((n) => n.type === "lotOuvrage" || n.type === "lotText")
            .map((n) => {
              // cleanup location & qt fields
              if (n.type === "lotOuvrage") {
                n.attrs.location = "";
                n.attrs.locations = [];
                n.attrs.qt = "";
              }
              return n;
            });
        } catch (e) {
          console.error(e);
          this.modals.import.ouvrages = [];
        }

        this.modals.import.page = 1;
      } else if (mode === "excel") {
        this.modalImportClose();
        this.modalImportFromExcelFetchColumns();
        this.modals.importFromExcel.show = true;
        this.modals.importFromExcel.page = 1;
      } else {
        this.modalImportClose();
      }
    },
    modalImportValidate() {
      // fetch ouvrages for project & lot
      const content = this.modals.import.selectedOuvrages;

      // import eventual content into current document
      if (content.length > 0) {
        toast.success("" + content.length + (content.length > 1 ? " ouvrages importés" : " ouvrage importé"));
        this.editor.commands.focus();

        this.editor.commands.insertContent(content);
        this.editor.commands.focus();
      } else {
        // todo popup message aucun ouvrage
        toast.info("Aucun ouvrage importé");
      }

      this.modalImportClose();
    },
    modalImportGetLotsForProject() {
      return this.projectToImport?.lots || [];
    },
    modalImportGetDocumentForProject() {
      return this.projectToImport?.document || {};
    },
    menuTableOfContentsToggle() {
      this.menuTableOfContents.show = !this.menuTableOfContents.show;
    },
    menuColorToggle() {
      this.menuColor.show = !this.menuColor.show;
    },
    beforeWindowUnload(e) {
      if (this.editor && this.leaveGuard) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  mounted() {
    console.log("----mounted----");
    //this.loadEditor();
    if (this.editor) {
      if (this.isStoreReady) {
        console.log("mounted 1");
        this.editor.chain().setContent(this.fetchFocusDoc()).run();
        this.$nextTick(() => {
          this.editor.commands.focus("start");
          this.scrollToLot(this.hash);
        });
      } else {
        console.log("mounted 2");
      }
    } else {
      this.loadEditor();
      if (this.isStoreReady) {
        console.log("mounted 1");
        this.editor.chain().setContent(this.fetchFocusDoc()).run();
        this.$nextTick(() => {
          this.scrollToLot(this.hash);
        });
      } else {
        console.log("mounted 2");
      }
    }
    this.$nextTick(() => {
      this.scrollToLot(this.hash);
    });
    console.log("----mounted end----");
  },
  watch: {
    project: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue?.id !== oldValue?.id || newValue.lots.length !== oldValue.lots.length) {
          console.log("project is different or new (id or lot count)");
          if (this.editor) {
            if (this.isStoreReady) {
              console.log("project 1");
              this.editor.off("update");
              this.editor.destroy();
              this.loadEditor();
              this.editor.chain().setContent(this.fetchFocusDoc()).run();
              this.$nextTick(() => {
                this.editor.commands.focus("start");
              });
            } else {
              console.log("project 2");
              this.editor.off("update");
              this.editor.destroy();
              this.loadEditor();
            }
          } else {
            console.log("project 3");
            this.loadEditor();
          }
        }
      },
    },
  },
  computed: {
    isDocumentEmpty() {
      return this?.project && this.project?.document && this.project.document === '"{}"';
    },
    hash() {
      return this.$route.hash || "";
    },
    isDescriptionDisplayModeCollapsed: {
      get() {
        return kalmStore.state.display.descriptionMode === "collapsed";
      },
      set(newValue) {
        const mode = newValue ? "collapsed" : "full";
        kalmStore.commit("updateDescriptionDisplayMode", mode);
      },
    },
    computedColorForTitle() {
      return this.currentUser?.org?.persoColorMain || "#195D5C";
    },
    computedFontForTitle() {
      return this?.currentUser?.org?.persoFontBody || "DM Serif";
    },
    isStoreReady() {
      return this.project && this.project?.document && this.fetchFocusDoc()?.type === "docType";
    },
    isModalActive() {
      return (
        kalmStore.state.display.profileToggled ||
        kalmStore.state.display.blur ||
        Object.values(this.modals).some((m) => m.show)
      );
    },
    currentFocus() {
      console.log("currentFocus");
      return kalmStore.getters.currentProjectFocus;
    },
    currentProject() {
      return this.project;
    },
    lots() {
      return this.currentProject?.lots;
    },
    getProjects() {
      return this.projectsSimple;
    },
    projectHasFeatureMultiLocation: () => {
      return this.project.featureMultiLocation;
    },
    budgetModule: {
      get() {
        return this.$store.getters.budgetModule;
      },
      set() {
        this.$store.commit("toggleBudgetModule");
      },
    },
    printValueLot: {
      get() {
        return this.$store.getters.printValueLot;
      },
      set() {
        this.$store.commit("togglePrintValueLot");
      },
    },
    printValueQt: {
      get() {
        return this.$store.getters.printValueQt;
      },
      set() {
        this.$store.commit("togglePrintValueQt");
      },
    },
    printValueUnitPrice: {
      get() {
        return this.$store.getters.printValueUnitPrice;
      },
      set() {
        this.$store.commit("togglePrintValueUnitPrice");
      },
    },
  },
  beforeUnmount() {
    if (this.editor) {
      this?.editor.destroy();
    }
    window.removeEventListener("keydown", this.handleKeydown);
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
};
</script>

<style lang="scss">
@media print {
  .print-no-padding {
    padding: 0 !important;
  }
}

.import-ouvrage-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Basic editor styles */
.tiptap {
  p {
    min-width: 1px;
  }

  img {
    max-width: 80%;
    height: auto;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  h1 {
    font-size: 22px !important;
    break-after: avoid !important;
    margin-left: 15px !important;
    color: v-bind(computedColorForTitle);
  }

  h2 {
    font-size: 22px !important;
    break-after: avoid !important;
    margin-left: 30px !important;
    color: v-bind(computedColorForTitle);
  }
}
</style>

<style>
.tooltip {
  z-index: 10000;
}

/* Removes the arrows from number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ProseMirror h4.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror h4 + p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror #lotText-node-content > p.is-empty:first-child::before {
  color: #adb5bd;
  content: "Bloc texte : ajoutez des généralités, un titre...";
  float: left;
  height: 0;
  pointer-events: none;
}

@media print {
  .tooltip {
    display: none !important;
    visibility: hidden !important;
  }
}
</style>

<style scoped>
.editor {
  border-radius: 20px;
  margin-top: 20px;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0 20px 30px; /*0 0px 40px 5px rgba(0, 0, 0, 0.2);*/
  background-color: white;
}

.editor-buttons-sticky {
  /*noinspection CssInvalidPropertyValue*/
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 180;
}

.drag-handle {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  right: 50px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ProseMirror-focused:focus {
  outline: none !important;
}

.ProseMirror:focus {
  outline: none !important;
}

.ProseMirror-focused:focus-visible {
  outline: none !important;
}

.ProseMirror:focus-visible {
  outline: none !important;
}

.floating-menu {
  display: flex;
  padding: 0.2rem;
  border-radius: 0.5rem;
  z-index: 100 !important;
}

.floating-menu button {
  position: relative;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
  z-index: 100 !important;
}

.floating-menu button:hover,
.floating-menu button.is-active {
  opacity: 1;
}

.editor-toolbar {
  background-color: #195d5b;
  opacity: 0.95;
  backdrop-filter: blur(10px);
}

.editor-toolbar.toolbar-top {
  border-radius: 20px 20px 0 0;
  min-height: 50px;
}

.editor-toolbar.toolbar-sticky {
  border-radius: 25px;
  min-height: 40px;
}

@media print {
  .card {
    box-shadow: none;
  }

  .print-hidden {
    display: none !important;
  }

  .editor {
    box-shadow: none;
  }
}
</style>
