<template>
  <div id="kt_aside" :class="drawerClass" class="aside bg-info print-hidden">
    <div class="aside-secondary overflow-y-auto d-flex flex-row-fluid bg-white">
      <!--begin::Workspace-->
      <div id="kt_aside_workspace" class="aside-workspace my-5 pe-4 ps-5">
        <!--begin::Logo-->
        <div id="kt_aside_logo" class="aside-logo ps-3">
          <router-link :to="{ name: 'dashboard' }">
            <img
              alt="Logo"
              class="mh-100px"
              fill="#01949A"
              src="/assets/media/logos/logo-compact-positive.png"
              @click="this.closeAside()"
            />
          </router-link>
        </div>
        <!--end::Logo-->
        <!--begin::Menu-->
        <div
          class="menu menu-column menu-rounded menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fs-6"
          data-kt-menu="true"
        >
          <div class="menu-wrapper menu-column">
            <!-- FIRST HALF - GENERAL LINKS -->
            <div class="mx-3 menu-item">
              <h4 class="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">GÉNÉRAL</h4>
              <div class="menu-sub menu-fit menu-sub-accordion show pb-4">
                <div v-for="item in general_items" :key="item.destination" class="menu-item">
                  <template v-if="item?.type === 'link'">
                    <a
                      :href="item.destination"
                      class="menu-link py-1 link-primary"
                      target="_blank"
                      v-bind:class="item.force_active || isPageActive(item.destination) ? 'active fw-bold' : ''"
                      @click="this.closeAside()"
                    >
                      <span v-if="item.icon !== undefined" class="menu-icon">
                        <i class="bi fs-3" v-bind:class="item.icon"></i>
                      </span>
                      <span class="menu-title">{{ item.name }}</span>
                    </a>
                  </template>
                  <template v-else>
                    <router-link
                      :to="{ name: item.destination, hash: item?.hash }"
                      class="menu-link py-1 link-primary"
                      v-bind:class="item.force_active || isPageActive(item.destination) ? 'active fw-bold' : ''"
                      @click="this.closeAside()"
                    >
                      <span v-if="item.icon !== undefined" class="menu-icon">
                        <i class="bi fs-3" v-bind:class="item.icon"></i>
                      </span>
                      <span class="menu-title">{{ item.name }}</span>
                    </router-link>
                  </template>
                </div>
              </div>
            </div>
            <!-- SECOND HALF - PROJECT SPECIFIC LINKS -->
            <div class="">
              <div
                class="mt-5 px-3 position-relative menu-item border border-muted rounded py-0"
                style="box-sizing: border-box"
              >
                <h4
                  class="position-absolute menu-content text-muted mb-0 fs-6 fw-bold text-uppercase py-0 bg-white"
                  style="top: -0.5em"
                >
                  PROJET
                </h4>
                <select
                  ref="mainTourProjectSwitch"
                  aria-label="Select project"
                  class="form-select form-select-solid mt-5"
                  @change="
                    updateFocus($event);
                    closeAside();
                  "
                  @click="updateFocus($event)"
                >
                  <option
                    v-for="project in sortedProjects.filter((p) => !p.archived)"
                    :key="project.id"
                    :name="project.name"
                    :selected="project.id === currentProjectFocus"
                    :value="project.id"
                  >
                    {{ project.name }}
                  </option>
                </select>
                <br />
                <div class="menu-sub menu-fit menu-sub-accordion show pb-3">
                  <div
                    v-for="item in project_items.filter(
                      (item) => !item?.group || (item?.group && this.currentUser?.groups?.includes(item.group))
                    )"
                    :key="item.destination"
                    class="menu-item"
                  >
                    <template v-if="item?.type === 'link'">
                      <a
                        v-if="!item.disabled && (!item.group || this.currentUser?.groups?.includes(item.group))"
                        :href="item.destination"
                        class="menu-link py-1 link-primary"
                        target="_blank"
                        v-bind:class="{
                          active: item.force_active || isPageActive(item.destination),
                          'fw-bold': item.force_active || isPageActive(item.destination),
                          disabled: item.disabled || (item.group && !this.currentUser?.groups?.includes(item.group)),
                        }"
                        @click="this.closeAside()"
                      >
                        <span v-if="item.icon !== undefined" class="menu-icon">
                          <i class="bi fs-3" v-bind:class="item.icon"></i>
                        </span>
                        <span class="menu-title">{{ item.name }}</span>
                      </a>
                    </template>
                    <template v-else>
                      <router-link
                        v-if="!item.disabled && (!item.group || this.currentUser?.groups?.includes(item.group))"
                        :to="{ name: item.destination, params: { id: currentProjectFocus } }"
                        class="menu-link py-1 link-primary"
                        v-bind:class="{
                          active: item.force_active || isPageActive(item.destination),
                          'fw-bold': item.force_active || isPageActive(item.destination),
                          disabled: item.disabled || (item.group && !this.currentUser?.groups?.includes(item.group)),
                        }"
                        @click="this.closeAside()"
                      >
                        <span v-if="item.icon !== undefined" class="menu-icon">
                          <i class="bi fs-3" v-bind:class="item.icon"></i>
                        </span>
                        <span class="menu-title">{{ item.name }}</span>
                      </router-link>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Menu-->
      </div>
      <!--end::Workspace-->
    </div>
  </div>
</template>

<script>
import { ALL_PROJECTS_SIMPLE_QUERY, CURRENT_USER_QUERY } from "@/graphql/graphql";
import kalmStore from "@/store";
import * as Sentry from "@sentry/vue";
import Intercom from "@intercom/messenger-js-sdk";

export default {
  name: "MainMenu",
  apollo: {
    projects: ALL_PROJECTS_SIMPLE_QUERY,
    currentUser: {
      query: CURRENT_USER_QUERY,
      result(queryResult) {
        if (!this.identified) {
          // avoid double identification event
          const user = queryResult.data.currentUser;

          // identify the user on posthog
          this.$posthog.identify(
            user.id, // Replace 'distinct_id' with your user's unique identifier
            { email: user.email, name: user.firstName + " " + user.lastName } // optional: set additional user properties
          );

          if (user?.org?.id) {
            this.$posthog.group("org", user.org.id, {
              name: user.org.name,
            });
          }

          // identify the user on sentry
          if (Sentry?.setUser) {
            Sentry.setUser({
              id: user.id,
              email: user.email,
              username: user.firstName + " " + user.lastName + " " + user?.org?.name,
            });
          } else {
            console.log("no sentry found, cannot set user");
          }

          Intercom({
            app_id: "fkttdj9f",
            api_base: "https://api-iam.eu.intercom.io",
            user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name: user.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
            orgId: user.org.id,
            orgName: user.org.name,
            isNew: user.isNew,
            isRisky: user.org.isRisky,
            isTrial: user.org.isTrial,
            //created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
          });

          this.identified = true;
        }
      },
    },
  },
  data() {
    return {
      identified: false,
      currentUser: {
        orgs: [],
        org: {
          id: "",
        },
        groups: [],
        isSuperuser: false,
      },
      general_items: [
        {
          name: "Tableau de bord",
          icon: "bi-house-door",
          force_active: false,
          id: "dashboard",
          destination: "dashboard",
        },
        {
          name: "Contacts",
          icon: "bi-people",
          force_active: false,
          id: "contacts",
          destination: "contacts",
        },
        {
          name: "Bibliothèques",
          icon: "bi-bookmark-check",
          force_active: false,
          id: "biblios",
          destination: "biblios",
        },
        {
          name: "Documents",
          icon: "bi-folder",
          force_active: false,
          id: "documents",
          destination: "documents",
        },
        {
          name: "Todo",
          icon: "bi-check2-square",
          force_active: false,
          id: "todos",
          destination: "todos",
          group: "feature_todos",
        },
        {
          name: "Projets",
          icon: "bi-collection",
          force_active: false,
          id: "projects",
          destination: "projects",
        },
        {
          name: "Communauté",
          type: "link",
          icon: "bi-person-up",
          force_active: false,
          id: "commu",
          destination: "https://kalmai.notion.site/La-communaut-Kalm-000946cbb3d14519a10aee6a39dbd54f?pvs=4",
        },
      ],
      project_items: [
        {
          name: "Cockpit",
          icon: "bi-bullseye",
          force_active: false,
          id: "project.home",
          destination: "project.home",
        },
        {
          name: "Descriptif",
          icon: "bi-file-text",
          force_active: false,
          id: "description",
          destination: "project.description",
        },
        {
          name: "Documents",
          icon: "bi-folder",
          force_active: false,
          id: "documents",
          destination: "project.documents",
        },
        {
          name: "Planning (ancien)",
          icon: "bi-calendar3",
          force_active: false,
          id: "project_planning",
          group: "feature_planning_old",
          destination: "project.planning",
        },
        {
          name: "Planning",
          icon: "bi-calendar3",
          force_active: false,
          id: "project_planning",
          group: "feature_planning_new",
          destination: "project.planning_new",
        },
        {
          name: "Consultation",
          icon: "bi-journals",
          force_active: false,
          disabled: false,
          id: "description",
          destination: "project.tender",
        },
        {
          name: "Notes projet",
          icon: "bi-journal-bookmark",
          force_active: false,
          id: "project_notes",
          destination: "project.notes",
          group: "feature_notes",
        },
        {
          name: "Suivi de chantier (ancien)",
          icon: "bi-card-checklist",
          force_active: false,
          id: "monitoring_old",
          destination: "project.monitoring_old",
          group: "feature_appsheet",
        },
        {
          name: "Suivi de chantier",
          icon: "bi-list-task",
          force_active: false,
          id: "tasks",
          destination: "project.tasks",
          group: "feature_chantier",
        },
        {
          name: "Todo",
          icon: "bi-check2-square",
          force_active: false,
          id: "project_todos",
          destination: "project.todos",
          group: "feature_todos",
        },
      ],
      windowWidth: window.innerWidth,
      asideMode: "",
      projects: [],
    };
  },
  methods: {
    closeAside() {
      kalmStore.commit("closeAside");
    },
    a() {
      this.$router.push({ name: "contacts" });
    },
    /**
     * Compares a menu page ID to the current route
     * @param page_id
     * @returns {boolean} t rue if page_id matches current route
     */
    isPageActive(page_id) {
      return String(this.$route.name).startsWith(page_id);
    },
    toggleAside() {
      kalmStore.commit("toggleAside");
    },
    updateAsideMode() {
      this.windowWidth = window.innerWidth;
      this.asideMode = this.windowWidth < 992 ? "drawer" : "permanent";
      return this.asideMode;
    },
    updateFocus(event) {
      // preventing a bug on firefox where selectedIndex is not defined
      if (event?.target?.selectedIndex || event?.target?.selectedIndex === 0) {
        const projectId = event.target.value || 0;
        const projectName = event.target[event.target.selectedIndex].name || "0";

        kalmStore.commit("setCurrentProjectFocus", { projectId, projectName });

        const current_route = String(this.$route.name);
        if (current_route === "project.home" || current_route.startsWith("project.")) {
          this.$router.push({ name: current_route, params: { id: projectId } });
        }
      }
    },
  },
  async mounted() {
    window.addEventListener("resize", this.updateAsideMode);

    const projects = await this.$apollo.query({ query: ALL_PROJECTS_SIMPLE_QUERY });

    if (
      !kalmStore.getters.currentProjectFocus ||
      kalmStore.getters.currentProjectFocus === 0 ||
      kalmStore.getters.currentProjectFocus === "0"
    ) {
      kalmStore.commit("setCurrentProjectFocus", { projectId: projects.data.projects[0].id });
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.updateAsideMode);
  },
  beforeMount() {
    this.updateAsideMode();
  },
  computed: {
    asideToggled() {
      return kalmStore.state.display.asideToggled;
    },
    drawerClass() {
      let mode = this.asideMode;
      let toggled = kalmStore.state.display.asideToggled;

      if (mode === "permanent") {
        return "";
      } else if (mode === "drawer" && !toggled) {
        return "drawer drawer-start";
      } else if (mode === "drawer" && toggled) {
        return "drawer drawer-start drawer-on";
      }
      return null;
    },
    currentProjectFocus() {
      return kalmStore.state.display.currentProjectFocus || 1;
    },
    sortedProjects() {
      return JSON.parse(JSON.stringify(this.projects)).sort(function (a, b) {
        if ("" + a.name < "" + b.name) return -1;
        if ("" + a.name > "" + b.name) return 1;
        return 0;
      });
    },
    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
a.disabled {
  pointer-events: none;
}

a.disabled span,
a.disabled span i {
  color: lightgray !important;
}

@media print {
  .print-hidden {
    display: none;
  }
}
</style>
