<template>
  <div :class="isModalActive ? 'blur-content' : ''" class="d-flex flex-column flex-column-fluid">
    <!--begin::toolbar-->
    <div id="kt_toolbar" class="toolbar mb-5">
      <div class="container d-flex flex-stack flex-wrap flex-sm-nowrap">
        <!--begin::Info-->
        <div class="d-flex flex-column align-items-start justify-content-center flex-wrap me-1">
          <!--begin::Title-->
          <h3 class="text-dark fw-bolder my-1">Mon studio</h3>
          <!--end::Title-->
          <!--begin::Breadcrumb-->
          <ul class="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
            <li class="breadcrumb-item">Paramètres</li>
            <li class="breadcrumb-item text-dark">Mon studio</li>
          </ul>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Info-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
          <router-link :to="{ name: 'settings.account_settings' }" class="btn btn-active-accent fw-bolder ms-3">
            Mon compte
          </router-link>
          <router-link :to="{ name: 'settings.studio_settings' }" class="btn btn-active-accent active fw-bolder ms-3">
            Mon studio
          </router-link>
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::toolbar-->
    <!--begin::Content-->
    <div id="kt_content" class="content fs-6 d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container row gx-0 gy-5">
        <!-- My studio -->
        <div class="card col-12">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Mon studio</span>
            </h3>
          </div>
          <div class="card-body pt-3 mt-3">
            <div class="">
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Nom de l'agence</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.name"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Niemeyer Studio"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">SIRET</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.infoSiret"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="339 863 417 00418"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Adresse</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <textarea
                      v-model="this.localCurrentUser.org.infoAddress"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="15 rue du Petit Musc&#10;75004 Paris 4ème"
                      type="text"
                    ></textarea>
                  </div>
                </div>
              </div>

              <!--              <h3 class="card-title align-items-start flex-column mt-5 mb-5">
                <span class="card-label fw-bolder text-dark fs-3">Paramètres du descriptif</span>
              </h3>-->

              <!--              <div class="row mb-3">
                <div class="col-lg-9">
                  <div class="form-check form-switch">
                    <input
                      id="persoAlwaysForceSave"
                      v-model="this.localCurrentUser.org.persoAlwaysForceSave"
                      class="form-check-input"
                      type="checkbox"
                    />
                    <label class="form-check-label" for="persoAlwaysForceSave"
                      >Forcer la sauvegarde automatique du descriptif</label
                    >
                  </div>
                </div>
              </div>-->
            </div>
            <div class="flex-column text-end">
              <button
                v-if="this.currentUser.role === 'OWNER'"
                :disabled="!this.localCurrentUserEdited"
                class="btn btn-primary btn-active-accent"
                @click="this.updateOrgProperties()"
              >
                Enregistrer
                <span
                  v-if="this.buttonOrgPropertiesLoading"
                  class="ms-1 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Chargement...</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <!-- Mon logo -->
        <div class="card col-12">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Mon logo</span>
            </h3>
          </div>
          <div class="card-body pt-3 mt-3">
            <div class="">
              <div class="row mb-3">
                <div class="position-relative" style="height: 200px; width: 200px">
                  <img
                    v-if="this.currentUser.org.logo"
                    :src="this.getLogoURL"
                    class="rounded-2"
                    height="200px"
                    width="200px"
                  />
                  <button
                    class="btn btn-secondary btn-icon btn-sm position-absolute top-0 end-0 me-3 mt-3"
                    @click="this.modalEditLogoShow()"
                  >
                    <i class="bi bi-pencil-fill"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- My team -->
        <div class="card col-12">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Mon équipe</span>
            </h3>
            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <li class="nav-item">
                  <button
                    v-if="this.currentUser.role === 'OWNER'"
                    class="nav-link btn btn-active-light btn-color-muted fw-bolder me-2"
                    @click="this.modalAddUserShow()"
                  >
                    + Ajouter
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 mt-n3">
            <div id="myTabTables2" class="tab-content mt-4">
              <!--begin::Table-->
              <div class="table-responsive">
                <table v-if="this.currentUser.org.team" class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="px-2 py-0 w-50px"></th>
                      <th class="px-2 py-0 w-100"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                      <th class="px-2 py-0 min-w-50px"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in this.currentUser.org.team" :key="user.id" class="bg-hover-light cursor-default">
                      <td class="px-0 py-3 rounded-start p-5">
                        <div class="symbol symbol-55px ms-5 mt-1 me-5">
                          <span class="symbol-label bg-light-primary align-middle align-items-end">
                            <img
                              v-if="user.photo"
                              :src="user.photo"
                              :style="'border: 5px solid ' + (user?.color || '#FFFAAA')"
                              alt="Logo"
                              class="mh-50px rounded rounded-circle"
                            />
                            <div
                              :style="
                                'background-color: ' +
                                (user?.color || '#FFFAAA') +
                                '; border: 5px solid ' +
                                (user?.color || '#FFFAAA')
                              "
                              class="d-flex h-50px w-50px my-auto rounded rounded-circle justify-content-center"
                            >
                              <span class="text-white align-self-center fs-1">{{
                                (user?.firstName[0] || "") + (user?.lastName[0] || "")
                              }}</span>
                            </div>
                          </span>
                        </div>
                      </td>
                      <td class="px-0">
                        <p class="text-gray-800 fw-bolder fs-6 my-1">{{ user.firstName + " " + user.lastName }}</p>
                        <span class="text-muted fw-bold d-block mt-1">{{ user.email }}</span>
                      </td>
                      <td class="px-0 text-center">
                        <div>
                          <span class="bg-light text-gray-800 border text-center rounded py-1 px-2 h-30px">{{
                            user.role
                          }}</span>
                        </div>
                      </td>
                      <td></td>
                      <td class="px-0 rounded-end">
                        <button
                          v-if="this.currentUser.role === 'OWNER'"
                          class="me-2 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                          @click="this.modalEditUserShow(user.id)"
                        >
                          <i class="bi bi-pencil align-baseline text-center"></i>
                        </button>
                        <button
                          v-if="this.currentUser.role === 'OWNER'"
                          class="btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                          @click="this.modalEditContactShow(user.linkedContact.id)"
                        >
                          <i class="bi bi-person align-baseline text-center"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>

        <div class="card col-12">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Couleurs et polices</span>
            </h3>
          </div>
          <div class="card-body pt-6 mt-3">
            <div class="">
              <h4 class="mb-0">Couleurs</h4>
              <div>
                <p class="text-muted">
                  Cette couleur est celle de votre charte graphique, elle sera utilisée dans tous les exports dans les
                  titres et certains éléments graphiques.
                </p>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Couleur principale</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.persoColorMain"
                      :style="'background-color: ' + this.localCurrentUser.org.persoColorMain"
                      class="form-control-color w-20px h-20px ms-4 me-0 my-auto mx-auto align-middle rounded-circle rounded-circle border-0"
                      type="color"
                    />
                    <input
                      v-model="this.localCurrentUser.org.persoColorMain"
                      :placeholder="this.localCurrentUser.org.persoColorMain"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <!-- Un petit encart qui explique que ces 6 couleurs constituent une palette qui sera disponible dans le descriptif et le suivi de chantier -->
              <div>
                <p class="text-muted mt-8">
                  Ces 6 couleurs constituent une palette qui sera disponible dans le descriptif et le suivi de chantier
                </p>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Couleur 1</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.persoColor1"
                      :style="'background-color: ' + this.localCurrentUser.org.persoColor1"
                      class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle"
                      type="color"
                    />
                    <input
                      v-model="this.localCurrentUser.org.persoColor1"
                      :placeholder="this.localCurrentUser.org.persoColor1"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Couleur 2</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.persoColor2"
                      :style="'background-color: ' + this.localCurrentUser.org.persoColor2"
                      class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle"
                      type="color"
                    />
                    <input
                      v-model="this.localCurrentUser.org.persoColor2"
                      :placeholder="this.localCurrentUser.org.persoColor2"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Couleur 3</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.persoColor3"
                      :style="'background-color: ' + this.localCurrentUser.org.persoColor3"
                      class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle"
                      type="color"
                    />
                    <input
                      v-model="this.localCurrentUser.org.persoColor3"
                      :placeholder="this.localCurrentUser.org.persoColor3"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Couleur 4</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.persoColor4"
                      :style="'background-color: ' + this.localCurrentUser.org.persoColor4"
                      class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle"
                      type="color"
                    />
                    <input
                      v-model="this.localCurrentUser.org.persoColor4"
                      :placeholder="this.localCurrentUser.org.persoColor4"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Couleur 5</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.persoColor5"
                      :style="'background-color: ' + this.localCurrentUser.org.persoColor5"
                      class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle"
                      type="color"
                    />
                    <input
                      v-model="this.localCurrentUser.org.persoColor5"
                      :placeholder="this.localCurrentUser.org.persoColor5"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Couleur 6</label>
                <div class="col-lg-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <input
                      v-model="this.localCurrentUser.org.persoColor6"
                      :style="'background-color: ' + this.localCurrentUser.org.persoColor6"
                      class="form-control-color border-0 w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle"
                      type="color"
                    />
                    <input
                      v-model="this.localCurrentUser.org.persoColor6"
                      :placeholder="this.localCurrentUser.org.persoColor6"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <h4 class="mt-7 mb-0">Polices</h4>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Titres</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input
                        v-model="this.localCurrentUser.org.persoFontTitle"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="DM Serif Text"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <label class="col-lg-3 col-form-label">Corps de texte</label>
                  <div class="col-lg-9">
                    <div class="input-group input-group-lg input-group-solid">
                      <input
                        v-model="this.localCurrentUser.org.persoFontBody"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="DM Sans"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.currentUser.role === 'OWNER'" class="flex-column text-end">
                <button
                  :disabled="!this.localCurrentUserEdited"
                  class="btn btn-primary btn-active-accent"
                  @click="this.updateOrgProperties()"
                >
                  Enregistrer
                  <span
                    v-if="this.buttonOrgPropertiesLoading"
                    class="ms-1 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Chargement...</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card col-12">
          <div class="card-header border-0 pt-5">
            <h3 class="card-label fw-bolder text-dark fs-3 pt-5">Compte rendu de chantier</h3>
          </div>
          <div class="card-body py-6 px-12 mt-3">
            <div class="ps-3">
              <div class="row mb-5">
                <div class="form-check">
                  <input
                    id="checkHideArchived"
                    v-model="this.localCurrentUser.org.persoTaskHideArchived"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                  />
                  <label class="form-check-label" for="checkHideArchived"> Masquer les tâches archivées </label>
                </div>
              </div>
              <div class="row mb-3">
                <h4 class="mb-0">Ordre des tâches</h4>
                <label class="col-lg-3 col-form-label">Tri 1</label>
                <select v-model="this.localCurrentUser.org.persoTaskSort1" class="form-select">
                  <option value="">Sélectionnez un tri</option>
                  <option value="status__order">Statut (croissant)</option>
                  <option value="-status__order">Statut (décroissant)</option>
                  <option value="start_date">Date de début (croissante)</option>
                  <option value="-start_date">Date de début (décroissante)</option>
                  <option value="end_date">Date de fin (croissante)</option>
                  <option value="-end_date">Date de fin (décroissante)</option>
                  <option value="assigned_company__name">Entreprise (croissante)</option>
                  <option value="-assigned_company__name">Entreprise (décroissante)</option>
                  <option value="updated_date">Dernière modif (croissante)</option>
                  <option value="-updated_date">Dernière modif (décroissante)</option>
                  <option value="created_time">Date de création (croissante)</option>
                  <option value="-created_time">Date de création (décroissante)</option>
                  <option value="numero">Numéro de l'ouvrage (croissant)</option>
                  <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                  <option value="title">Titre de l'ouvrage (croissant)</option>
                  <option value="-title">Titre de l'ouvrage (décroissant)</option>
                  <option value="order">Ordre personnalisé (croissant)</option>
                  <option value="-order">Ordre personnalisé (décroissant)</option>
                </select>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Tri 2</label>
                <select v-model="this.localCurrentUser.org.persoTaskSort2" class="form-select">
                  <option value="">Sélectionnez un tri</option>
                  <option value="status__order">Statut (croissant)</option>
                  <option value="-status__order">Statut (décroissant)</option>
                  <option value="start_date">Date de début (croissante)</option>
                  <option value="-start_date">Date de début (décroissante)</option>
                  <option value="end_date">Date de fin (croissante)</option>
                  <option value="-end_date">Date de fin (décroissante)</option>
                  <option value="assigned_company__name">Entreprise (croissante)</option>
                  <option value="-assigned_company__name">Entreprise (décroissante)</option>
                  <option value="updated_date">Dernière modif (croissante)</option>
                  <option value="-updated_date">Dernière modif (décroissante)</option>
                  <option value="created_time">Date de création (croissante)</option>
                  <option value="-created_time">Date de création (décroissante)</option>
                  <option value="numero">Numéro de l'ouvrage (croissant)</option>
                  <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                  <option value="title">Titre de l'ouvrage (croissant)</option>
                  <option value="-title">Titre de l'ouvrage (décroissant)</option>
                  <option value="order">Ordre personnalisé (croissant)</option>
                  <option value="-order">Ordre personnalisé (décroissant)</option>
                </select>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Tri 3</label>
                <select v-model="this.localCurrentUser.org.persoTaskSort3" class="form-select">
                  <option value="">Sélectionnez un tri</option>
                  <option value="status__order">Statut (croissant)</option>
                  <option value="-status__order">Statut (décroissant)</option>
                  <option value="start_date">Date de début (croissante)</option>
                  <option value="-start_date">Date de début (décroissante)</option>
                  <option value="end_date">Date de fin (croissante)</option>
                  <option value="-end_date">Date de fin (décroissante)</option>
                  <option value="assigned_company__name">Entreprise (croissante)</option>
                  <option value="-assigned_company__name">Entreprise (décroissante)</option>
                  <option value="updated_date">Dernière modif (croissante)</option>
                  <option value="-updated_date">Dernière modif (décroissante)</option>
                  <option value="created_time">Date de création (croissante)</option>
                  <option value="-created_time">Date de création (décroissante)</option>
                  <option value="numero">Numéro de l'ouvrage (croissant)</option>
                  <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                  <option value="title">Titre de l'ouvrage (croissant)</option>
                  <option value="-title">Titre de l'ouvrage (décroissant)</option>
                  <option value="order">Ordre personnalisé (croissant)</option>
                  <option value="-order">Ordre personnalisé (décroissant)</option>
                </select>
              </div>
              <div class="row mb-3">
                <label class="col-lg-3 col-form-label">Tri 4</label>
                <select v-model="this.localCurrentUser.org.persoTaskSort4" class="form-select">
                  <option value="">Sélectionnez un tri</option>
                  <option value="status__order">Statut (croissant)</option>
                  <option value="-status__order">Statut (décroissant)</option>
                  <option value="start_date">Date de début (croissante)</option>
                  <option value="-start_date">Date de début (décroissante)</option>
                  <option value="end_date">Date de fin (croissante)</option>
                  <option value="-end_date">Date de fin (décroissante)</option>
                  <option value="assigned_company__name">Entreprise (croissante)</option>
                  <option value="-assigned_company__name">Entreprise (décroissante)</option>
                  <option value="updated_date">Dernière modif (croissante)</option>
                  <option value="-updated_date">Dernière modif (décroissante)</option>
                  <option value="created_time">Date de création (croissante)</option>
                  <option value="-created_time">Date de création (décroissante)</option>
                  <option value="numero">Numéro de l'ouvrage (croissant)</option>
                  <option value="-numero">Numéro de l'ouvrage (décroissant)</option>
                  <option value="title">Titre de l'ouvrage (croissant)</option>
                  <option value="-title">Titre de l'ouvrage (décroissant)</option>
                  <option value="order">Ordre personnalisé (croissant)</option>
                  <option value="-order">Ordre personnalisé (décroissant)</option>
                </select>
              </div>
              <div v-if="this.currentUser.role === 'OWNER'" class="flex-column text-end">
                <button
                  :disabled="!this.localCurrentUserEdited"
                  class="btn btn-primary btn-active-accent"
                  @click="this.updateOrgProperties()"
                >
                  Enregistrer
                  <span
                    v-if="this.buttonOrgPropertiesLoading"
                    class="ms-1 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Chargement...</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-for="entity in ['BIBLIO', 'PROJECT', 'CANEVA']" :key="entity" class="card col-12">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder text-dark fs-3">Mes tags de {{ entityFormattedName(entity) }}</span>
            </h3>
            <div class="card-toolbar">
              <ul class="nav nav-pills nav-pills-sm nav-light">
                <li class="nav-item">
                  <button
                    class="nav-link btn btn-active-light btn-color-muted fw-bolder me-2"
                    @click="this.modalCreateTagShow(entity)"
                  >
                    + Ajouter
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 mt-n3">
            <div :id="'TagsTable-' + entity" class="tab-content mt-4">
              <!--begin::Table-->
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th class="px-2 py-0 w-50px"></th>
                      <th class="px-2 py-0 w-100"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                      <th class="px-2 py-0 min-w-50px"></th>
                      <th class="px-2 py-0 min-w-100px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="tag in this.tagsByEntity[entity]" :key="tag.id" class="bg-hover-light cursor-default">
                      <td class="px-0 py-3 rounded-start p-5">
                        <div :style="{ background: tag.color }" class="symbol symbol-55px ms-5 mt-1 me-5">
                          <span class="symbol-label bg-light-primary align-middle align-items-end">
                            <span class="text-white align-self-center fs-1"></span>
                          </span>
                        </div>
                      </td>

                      <td class="px-0">
                        <p class="text-gray-800 fw-bolder fs-6 my-1">
                          {{ tag.name }}
                          <span v-if="tag.type === 'DEFAULT'" class="text-muted ms-2 fw-lighter">Tag par défaut</span>
                        </p>
                        <span class="text-muted fw-bold d-block mt-1">{{ tag.color }}</span>
                      </td>
                      <td></td>
                      <td></td>
                      <td class="px-0 rounded-end">
                        <button
                          :disabled="tag.type === 'DEFAULT'"
                          class="me-2 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                          @click="modalEditTagShow(tag)"
                        >
                          <i class="bi bi-pencil align-baseline text-center"></i>
                        </button>
                        <button
                          :disabled="tag.type === 'DEFAULT'"
                          class="me-2 btn btn-icon btn-bg-light btn-text-gray-600 btn-active-primary btn-sm btn-active-white"
                          @click="modalDeleteTagShow(tag.id)"
                        >
                          <i class="bi bi-trash align-baseline text-center"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Content-->
  </div>
  <div>
    <!-- MODAL EDIT CONTACT -->
    <div :style="this.modals.editContact.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditContactCancel()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div ref="contactEditModalForm" class="pb-5 w-100 w-sm-400px">
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <div class="pb-3">
                          <h3 class="fw-bolder text-dark display-6">Modifier le contact</h3>
                          <p class="">
                            Voici le profil tel qu'il apparaîtra dans les différents exports et comptes-rendus s'il est
                            ajouté comme MOE sur un projet.
                          </p>
                        </div>

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Nom</label>
                          <input
                            v-model="this.modals.editContact.current.name"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="Jean & Laura Dupont"
                            type="text"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Téléphone</label>
                          <input
                            v-model="this.modals.editContact.current.phone"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="+33 6 23 45 67 89"
                            type="text"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                          <input
                            v-model="this.modals.editContact.current.email"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="jean.dupont@gmail.com"
                            type="text"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Informations supplémentaires</label>
                          <textarea
                            v-model="this.modals.editContact.current.infoComment"
                            class="form-control form-control-lg form-control-solid"
                            type="text"
                          ></textarea>
                        </div>
                        <!--end::Form Group-->

                        <p>Alternative : Affichage personnalisé du contact</p>

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Informations personnalisées - ligne 1</label
                          >
                          <input
                            v-model="this.modals.editContact.current.infoLine1"
                            class="form-control form-control-lg form-control-solid"
                            type="text"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Informations personnalisées - lignes 2+</label
                          >
                          <textarea
                            v-model="this.modals.editContact.current.infoLine2"
                            class="form-control form-control-lg form-control-solid"
                            type="text"
                          ></textarea>
                        </div>
                        <!--end::Form Group-->

                        <h2 class="mt-5">Aperçu</h2>
                        <div class="border border-primary rounded p-3">
                          <div
                            v-if="
                              this.modals.editContact.current.infoLine1 || this.modals.editContact.current.infoLine2
                            "
                          >
                            <p v-if="this.modals.editContact.current.infoLine1" class="fw-bolder mb-0">
                              {{ this.modals.editContact.current.infoLine1 }}
                            </p>
                            <p
                              v-if="this.modals.editContact.current.infoLine2"
                              class="mb-0"
                              style="white-space: pre-wrap"
                            >
                              {{ this.modals.editContact.current.infoLine2 }}
                            </p>
                          </div>
                          <div v-else>
                            <p v-if="this.modals.editContact.current.name" class="fw-bolder mb-0">
                              {{ this.modals.editContact.current.name }}
                            </p>
                            <p v-if="this.modals.editContact.current.phone" class="mb-0">
                              {{ this.modals.editContact.current.phone }}
                            </p>
                            <p v-if="this.modals.editContact.current.email" class="mb-0">
                              {{ this.modals.editContact.current.email }}
                            </p>
                            <p
                              v-if="this.modals.editContact.current.infoComment"
                              class="mb-0"
                              style="white-space: pre-wrap"
                            >
                              {{ this.modals.editContact.current.infoComment }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalEditContactCancel()">Annuler</button>
                        <button
                          class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                          @click="modalEditContactValidate()"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL ADD USER -->
    <div :style="this.modals.addUser.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalAddUserClose()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div ref="contactEditModalForm" class="pb-5 w-100 w-sm-400px">
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <div class="pb-3">
                          <h3 class="fw-bolder text-dark display-6">Ajouter un utilisateur</h3>
                        </div>

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Prénom</label>
                          <input
                            v-model="this.modals.addUser.newUser.firstName"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="Oscar"
                            type="text"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Nom</label>
                          <input
                            v-model="this.modals.addUser.newUser.lastName"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="Niemeyer"
                            type="text"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">E-mail</label>
                          <input
                            v-model="this.modals.addUser.newUser.email"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="oscar@niemeyer.archi"
                            type="email"
                          />
                        </div>
                        <!--end::Form Group-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalAddUserClose()">Annuler</button>
                        <button
                          :disabled="
                            !this.modals.addUser.newUser.firstName ||
                            !this.modals.addUser.newUser.lastName ||
                            !this.modals.addUser.newUser.email.includes('@')
                          "
                          class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                          @click="modalAddUserValidate()"
                        >
                          Ajouter
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL EDIT USER -->
    <div :style="this.modals.editUser.show ? 'display: block;' : 'display: none;'" class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered mw-600px">
        <div class="modal-content mx-5">
          <div class="container px-10 py-10">
            <div class="modal-header p-0 d-flex justify-content-end border-0">
              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-light-primary" @click="modalEditUserClose()">
                <i class="bi bi-x-lg"></i>
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body p-0">
              <!--begin::Stepper-->
              <div class="stepper stepper-1 d-flex flex-column flex-md-row flex-row-fluid between">
                <!--begin::Content-->
                <div class="d-flex flex-row-fluid justify-content-center">
                  <!--begin::Form-->
                  <div ref="contactEditModalForm" class="pb-5 w-100 w-sm-400px">
                    <!--begin::Step-->
                    <div class="pb-5 completed">
                      <div class="w-100">
                        <div class="pb-3">
                          <h3 class="fw-bolder text-dark display-6">Modifier les infos du compte</h3>
                        </div>

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Prénom</label>
                          <input
                            v-model="this.modals.editUser.user.firstName"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="Jean & Laura Dupont"
                            type="text"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Nom</label>
                          <input
                            v-model="this.modals.editUser.user.lastName"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="Jean & Laura Dupont"
                            type="text"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div class="fv-row mb-4">
                          <label class="fs-6 fw-bolder text-dark form-label">Couleur</label>
                          <div class="input-group input-group-lg input-group-solid">
                            <div
                              :style="'background-color: ' + this.modals.editUser.user.color"
                              class="w-20px h-20px ms-4 me-0 my-auto align-middle rounded-circle rounded-circle"
                            ></div>
                            <input
                              v-model="this.modals.editUser.user.color"
                              :placeholder="this.modals.editUser.user.color"
                              class="form-control form-control-lg form-control-solid"
                              type="text"
                            />
                          </div>
                        </div>
                        <!--end::Form Group-->
                      </div>
                    </div>
                    <!--end::Step-->

                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <div>
                        <button class="btn btn-lg btn-light" @click="modalEditUserClose()">Annuler</button>
                        <button
                          class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                          @click="modalEditUserValidate()"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Content-->
              </div>
              <!--end::Stepper-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreateTagModal
    v-if="this.modals.createTag.show"
    v-model="this.modals.createTag.newTag"
    @close="modalCreateTagClose"
    @validate="modalCreateTagValidate"
  />
  <CreateTagModal
    v-if="this.modals.editTag.show"
    v-model="this.modals.editTag.tag"
    buttonLabel="Mettre à jour"
    headerLabel="Modification du tag"
    @close="modalEditTagClose"
    @validate="modalEditTagValidate"
  />
  <DeleteTagModal v-if="this.modals.deleteTag.show" @close="modalDeleteTagClose" @validate="modalDeleteTagValidate" />
  <KalmModal v-if="this.modals.editLogo.show" modal-title="Modifier mon logo" @close="this.modalEditLogoCancel()">
    <template v-slot:body>
      <div class="form-group mb-3">
        <label class="text-muted d-block">Photo de couverture</label>
        <input
          accept="image/png,image/jpg,image/jpeg"
          class="form-control form-control-lg form-control-solid"
          type="file"
          @change="this.modalEditLogoHandleFileChange($event)"
        />
      </div>
      <!--      <div class="form-group mb-3">
        <label class="text-muted" for="deleteExistingLogo">Retirer le logo existant</label>
        <input
          id="deleteExistingLogo"
          ref="deleteExistingLogo"
          v-model="this.modals.editLogo.deleteExisting"
          class="form-check-input ms-2"
          type="checkbox"
          @change="this.modalEditLogoHandleDeleteChange($event)"
        />
      </div>-->
      <div class="">
        <!-- IMAGE PREVIEW -->
        <p class="text-muted">Aperçu de la future photo :</p>
        <img
          v-if="this.modals.editLogo.preview"
          :src="this.modals.editLogo.preview"
          alt="Image de couverture du projet"
          class="card-img-top mh-150px object-fit-cover"
        />
        <p class="text-center text-muted">Les dimensions de l'image doivent être inférieures à 500 x 500px</p>
        <p v-if="this.modals.editLogo.error" class="text-danger">
          {{ this.modals.editLogo.error }}
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <button class="btn btn-light" @click="this.modalEditLogoCancel()">Annuler</button>
      <button
        :disabled="!this.modalEditLogoCanValidate()"
        class="btn btn-primary"
        @click="this.modalEditLogoValidate()"
      >
        Enregistrer
      </button>
    </template>
  </KalmModal>
</template>

<script>
import { CURRENT_USER_QUERY, MUTATION_ORG_UPDATE, MUTATION_USER_CREATE, MUTATION_USER_UPDATE } from "@/graphql/graphql";
import kalmStore from "@/store";
import { ALL_CONTACTS_QUERY, MUTATION_CONTACT_UPDATE } from "@/views/contacts/data/contacts_graphql";
import { ALL_TAGS, CREATE_TAG, DELETE_TAG, UPDATE_TAG } from "../tags/data/tags_graphql";
import { toast } from "vue3-toastify";
import CreateTagModal from "../tags/presentation/components/CreateTagModal.vue";
import DeleteTagModal from "../tags/presentation/components/DeleteTagModal.vue";
import KalmModal from "@/views/_core/components/KalmModal.vue";

function sortContacts(a, b) {
  return ("" + a.name).localeCompare("" + b.name);
}

export default {
  name: "SettingsAccountPage",
  components: {
    KalmModal,
    CreateTagModal,
    DeleteTagModal,
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      result({ data }) {
        this.localCurrentUser = JSON.parse(JSON.stringify(data.currentUser));
        this.localCurrentUserEdited = false;
      },
    },
    contacts: ALL_CONTACTS_QUERY,
    allTags: ALL_TAGS,
  },
  data() {
    return {
      allTags: [],
      buttonOrgPropertiesLoading: false,
      currentUser: {
        email: "",
        firstName: "",
        lastName: "",
        org: {
          logo: null,
        },
      },
      localCurrentUserEdited: false,
      localCurrentUser: {
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        org: {
          name: "",
        },
      },
      contacts: [],
      modals: {
        editContact: {
          show: false,
          id: "",
          current: {
            id: "",
            type: "",
            company: "",
            name: "",
            role: "",
            email: "",
            phone: "",
            infoSiret: "",
            infoRole: "",
            infoInsurance: "",
            infoAddress: "",
            infoTva: "",
            infoComment: "",
            infoLine1: "",
            infoLine2: "",
          },
        },
        addUser: {
          show: false,
          newUser: {
            firstName: "",
            lastName: "",
            email: "",
          },
        },
        editUser: {
          show: false,
          user: {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          },
        },
        createTag: {
          show: false,
          callback: null,
          newTag: {
            name: "",
            color: "",
            entity: "",
          },
        },
        editTag: {
          show: false,
          id: "",
          tag: {
            name: "",
            color: "",
          },
        },
        deleteTag: {
          show: false,
          id: "",
        },
        editLogo: {
          show: false,
          deleteExisting: false,
          error: null,
          file: null,
          preview: "",
        },
      },
    };
  },
  methods: {
    modalEditLogoShow() {
      this.modals.editLogo.show = true;
    },
    modalEditLogoCancel() {
      this.modals.editLogo.show = false;
    },
    modalEditLogoValidate() {
      this.$apollo.mutate({
        mutation: MUTATION_ORG_UPDATE,
        variables: {
          id: this.localCurrentUser.org.id,
          newLogo: this.modals.editLogo.file,
        },
      });
      this.modalEditLogoCancel();
    },
    modalEditLogoCanValidate() {
      return (
        this.modals.editLogo.file && (this.modals.editLogo.file === "delete" || this.modals.editLogo.error === null)
      );
    },
    async modalEditLogoHandleFileChange($event) {
      if (this.modals.editLogo.deleteExisting) {
        this.modals.editLogo.file = "delete";
        this.modals.editLogo.preview = "https://app.kalm.ai/static/img/kalm-bg.png";
      } else if ($event.target.files[0]) {
        this.modals.editLogo.preview = URL.createObjectURL($event.target.files[0]);
        this.modals.editLogo.error = await this.isValidImageFile(this.modals.editLogo.preview);
        this.modals.editLogo.file = $event.target.files[0];
      }
    },
    async isValidImageFile(urlFile) {
      const error = "Erreur : les dimensions de l'image doivent être inférieures à 500 x 500px";
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          if (img.width <= 2000 && img.height <= 2000) {
            resolve(null);
          } else {
            resolve(error);
          }
        };
        img.onerror = () => resolve(error);
        img.src = urlFile;
      });
    },
    modalEditLogoHandleDeleteChange($event) {
      console.log("333", $event.target.checked);
      if ($event.target.checked) {
        this.modals.editLogo.file = "delete";
        this.$refs.deleteExistingLogo.value = null;
        this.modals.editLogo.preview = "https://app.kalm.ai/static/img/kalm-bg.png";
      } else {
        this.modals.editLogo.file = null;
        this.$refs.deleteExistingLogo.value = null;
        this.modals.editLogo.preview = this.project?.Logo || "https://app.kalm.ai/static/img/kalm-bg.png";
      }
    },
    async addTags(entity) {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_TAG,
          variables: {
            entity,
            name: this.modals.createTag.newTag.name,
            color: this.modals.createTag.newTag.color,
          },
          refetchQueries: [{ query: ALL_TAGS }],
        });
        toast.success("Le tag a bien été ajouté");
      } catch (e) {
        toast.error("Une erreur est survenuelors de la création du tag");
        console.error(e);
      }
    },
    modalEditTagClose() {
      this.modals.editTag.show = false;
    },
    modalEditTagShow(tag) {
      this.modals.editTag.show = true;
      this.modals.editTag.id = tag.id;
      this.modals.editTag.tag = {
        name: tag.name,
        color: tag.color,
      };
    },
    async modalEditTagValidate() {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_TAG,
          variables: {
            id: this.modals.editTag.id,
            name: this.modals.editTag.tag.name,
            color: this.modals.editTag.tag.color,
          },
          refetchQueries: [{ query: ALL_TAGS }],
        });
        toast.success("Le tag a bien été modifié");
      } catch (e) {
        toast.error("Une erreur est survenue lors de la modification du tag");
        console.error(e);
      }
      this.modalEditTagClose();
    },
    modalDeleteTagShow(id) {
      this.modals.deleteTag.show = true;
      this.modals.deleteTag.id = id;
    },
    modalDeleteTagClose() {
      this.modals.deleteTag.show = false;
      this.modals.deleteTag.id = "";
    },
    async modalDeleteTagValidate() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TAG,
          variables: {
            id: this.modals.deleteTag.id,
          },
          refetchQueries: [{ query: ALL_TAGS }],
        });
        toast.success("Le tag a bien été supprimé");
      } catch (e) {
        toast.error("Une erreur est survenue lors de la suppression du tag");
        console.error(e);
      }
      this.modalDeleteTagClose();
    },
    modalCreateTagShow(entity) {
      this.modals.createTag.show = true;
      this.modals.createTag.newTag.entity = entity;
    },
    modalCreateTagClose() {
      this.modals.createTag.show = false;
      this.modals.createTag.newTag = {
        name: "",
        color: "",
        entity: "",
      };
    },
    modalCreateTagValidate() {
      this.addTags(this.modals.createTag.newTag.entity);
      this.modalCreateTagClose();
    },
    entityFormattedName(entity) {
      switch (entity) {
        case "BIBLIO":
          return "bibliothèque";
        case "PROJECT":
          return "projet";
        case "CANEVA":
          return "templates";
        default:
          return "";
      }
    },
    modalAddUserShow() {
      this.modals.addUser.show = true;
    },
    modalAddUserClose() {
      this.modals.addUser.show = false;
    },
    modalAddUserValidate() {
      this.$apollo
        .mutate({
          mutation: MUTATION_USER_CREATE,
          variables: {
            firstName: this.modals.addUser.newUser.firstName,
            lastName: this.modals.addUser.newUser.lastName,
            email: this.modals.addUser.newUser.email,
          },
          update: (store, { data: { userCreate } }) => {
            // Read the data from our cache for this query.
            let data = store.readQuery({ query: CURRENT_USER_QUERY });

            // Add new user from the mutation to cache

            data = {
              currentUser: {
                ...data.currentUser,
                org: {
                  ...data.currentUser.org,
                  team: [...data.currentUser.org.team, userCreate.user],
                },
              },
            };

            // Write our data back to the cache.
            store.writeQuery({ query: CURRENT_USER_QUERY, data });
          },
        })
        .then(() => {
          toast.success("L'utilisateur a bien été ajouté");
        })
        .catch((e) => {
          toast.error("Une erreur est survenue lors de l'ajout de l'utilisateur");
          console.error(e);
        });

      this.modalAddUserClose();
    },
    modalEditUserShow(id) {
      this.modals.editUser.user = { ...this.currentUser.org.team.find((u) => u.id === id) };
      this.modals.editUser.show = true;
    },
    modalEditUserClose() {
      this.modals.editUser.show = false;
    },
    modalEditUserValidate() {
      this.$apollo.mutate({
        mutation: MUTATION_USER_UPDATE,
        variables: {
          id: this.modals.editUser.user.id,
          firstName: this.modals.editUser.user.firstName,
          lastName: this.modals.editUser.user.lastName,
          phone: this.modals.editUser.user.phone,
          color: this.modals.editUser.user.color,
        },
      });

      this.modalEditUserClose();
    },
    getContactById(id) {
      return this.contacts.find((c) => c.id === id);
    },
    modalEditContactShow(id) {
      if (id) {
        this.modals.editContact.show = true;
        this.modals.editContact.id = id;
        this.modals.editContact.current.name = this.getContactById(id).name;
        this.modals.editContact.current.email = this.getContactById(id).email;
        this.modals.editContact.current.phone = this.getContactById(id).phone;
        this.modals.editContact.current.company = this.getContactById(id).company;
        this.modals.editContact.current.type = this.getContactById(id).type;
        this.modals.editContact.current.infoSiret = this.getContactById(id).infoSiret;
        this.modals.editContact.current.infoRole = this.getContactById(id).infoRole;
        this.modals.editContact.current.infoInsurance = this.getContactById(id).infoInsurance;
        this.modals.editContact.current.infoComment = this.getContactById(id).infoComment;
        this.modals.editContact.current.infoAddress = this.getContactById(id).infoAddress;
        this.modals.editContact.current.infoTva = this.getContactById(id).infoTva;
        this.modals.editContact.current.infoLine1 = this.getContactById(id).infoLine1;
        this.modals.editContact.current.infoLine2 = this.getContactById(id).infoLine2;
      }
    },
    modalEditContactCancel() {
      this.modals.editContact.show = false;
    },
    modalEditContactValidate() {
      this.modals.editContact.show = false;

      const contactId = this.modals.editContact.id;
      const newContact = {
        name: this.modals.editContact.current.name,
        email: this.modals.editContact.current.email,
        phone: this.modals.editContact.current.phone,
        company: this.modals.editContact.current.company,
        type: this.modals.editContact.current.type,
        infoSiret: this.modals.editContact.current.infoSiret || "",
        infoRole: this.modals.editContact.current.infoRole || "",
        infoInsurance: this.modals.editContact.current.infoInsurance || "",
        infoComment: this.modals.editContact.current.infoComment || "",
        infoAddress: this.modals.editContact.current.infoAddress || "",
        infoTva: this.modals.editContact.current.infoTva || "",
        infoLine1: this.modals.editContact.current.infoLine1 || "",
        infoLine2: this.modals.editContact.current.infoLine2 || "",
      };

      if (contactId) {
        this.$apollo.mutate({
          mutation: MUTATION_CONTACT_UPDATE,
          variables: {
            contactId,
            newContact,
          },
          update: (store, { data: { contactUpdate } }) => {
            // Read the data from our cache for this query.
            let data = store.readQuery({ query: ALL_CONTACTS_QUERY });

            // Add new contact from the mutation to cache
            data = {
              ...data,
              contacts: [...data.contacts.filter((c) => c.id !== contactId), contactUpdate.contact].sort(sortContacts),
            };

            // Write our data back to the cache.
            store.writeQuery({ query: ALL_CONTACTS_QUERY, data });
          },
        });
      }
    },
    updateOrgProperties() {
      this.buttonOrgPropertiesLoading = true;
      this.$apollo.mutate({
        mutation: MUTATION_ORG_UPDATE,
        variables: {
          id: this.localCurrentUser.org.id,
          name: this.localCurrentUser.org.name,
          infoSiret: this.localCurrentUser.org.infoSiret,
          infoAddress: this.localCurrentUser.org.infoAddress,
          persoAlwaysForceSave: this.localCurrentUser.org.persoAlwaysForceSave,
          persoColorMain: this.localCurrentUser.org.persoColorMain,
          persoColor1: this.localCurrentUser.org.persoColor1,
          persoColor2: this.localCurrentUser.org.persoColor2,
          persoColor3: this.localCurrentUser.org.persoColor3,
          persoColor4: this.localCurrentUser.org.persoColor4,
          persoTaskSort1: this.localCurrentUser.org.persoTaskSort1,
          persoTaskSort2: this.localCurrentUser.org.persoTaskSort2,
          persoTaskSort3: this.localCurrentUser.org.persoTaskSort3,
          persoTaskSort4: this.localCurrentUser.org.persoTaskSort4,
          persoTaskHideArchived: this.localCurrentUser.org.persoTaskHideArchived,
          persoColor5: this.localCurrentUser.org.persoColor5,
          persoColor6: this.localCurrentUser.org.persoColor6,
          persoFontBody: this.localCurrentUser.org.persoFontBody,
          persoFontTitle: this.localCurrentUser.org.persoFontTitle,
        },
        update: () => {
          this.buttonOrgPropertiesLoading = false;
        },
      });
    },
  },
  computed: {
    isModalActive: function () {
      return (
        kalmStore.state.display.profileToggled ||
        this.modals.editContact.show ||
        this.modals.addUser.show ||
        this.modals.editUser.show
      );
    },
    biblioTags() {
      return this.allTags.filter((t) => t.entity === "BIBLIO");
    },
    projectTags() {
      return this.allTags.filter((t) => t.entity === "PROJECT");
    },
    canevaTags() {
      return this.allTags.filter((t) => t.entity === "CANEVA");
    },
    tagsByEntity() {
      return {
        BIBLIO: this.biblioTags,
        PROJECT: this.projectTags,
        CANEVA: this.canevaTags,
      };
    },
    internalContacts() {
      return this.contacts.filter((c) => c?.type === "INTERNAL").sort(sortContacts);
    },
    getLogoURL() {
      return (
        (this.currentUser.org.logo.startsWith("http") ? "" : process.env.VUE_APP_URL_BACK) + this.currentUser.org.logo
      );
    },
  },
  watch: {
    localCurrentUser: {
      handler() {
        if (JSON.stringify(this.localCurrentUser) !== JSON.stringify(this.currentUser)) {
          this.localCurrentUserEdited = true;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
